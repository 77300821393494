<template>
    <div >
      <div class="main-base-card" ref="refCardMain" :style="`max-height:${maxHeight};min-height:${minHeight}`">
        <div
        v-for="(item, i) in response" :key="i"
        @click.stop="$emit('clickCard',{item,i})"
        :class="['baseCard border-top-1-gray',`c-bg-${background}`,
        response.length == (i +1) ? 'border-bottom-1-gray' : '',
        `${cardPadding}`
        ]"
        >
          <div :class="['content-card','w-full',cardSource.lastBlock ? 'c-col-s-10' : 'c-col-s-12']">
            <template v-for="(field,index) in cardSource.fields" :key="index">
              <template v-if="
              ((item[field.key] || (field.key && field.key.indexOf('.') > -1)) && !field.condition) ||
              (field.onlyTitle && !field.condition) ||
              (field.condition && field.condition(item)) ||
              (field.onlyValue && !field.condition)">

                  <slot :name="field.key" v-bind="{item,index}" >
                  <div :class="[`${field.colsField ?? colsField}`]" >
                    <!-- obtener solo titulo -->
                    <span v-if="field.onlyTitle && (!field.condition) || (field.condition && field.condition(item))" :class="[field.titleClass ?? titleClass]">
                      {{field.title}}
                    </span>
                    <!-- obtener solo valor y  key de objetos -->
                    <span v-if="field.onlyValue && field.key && field.key.indexOf('.') > -1" :class="[field.valueClass ?? valueClass]">
                      {{getProperty(item,field.key)}}
                    </span>
                    <!-- obtener solo valor y key normal -->
                    <span v-else-if="
                    field.onlyValue && (field.key && field.key.indexOf('.') < 0 && item[field.key]) ||
                    (!field.key) " :class="[field.valueClass ?? valueClass]
                    ">
                      {{field.format ? field.format(item) : item[field.key]}}
                    </span>
                    <!-- obtener titulo y valor con key objeto -->
                    <template v-else-if="field.key && field.key.indexOf('.') > -1">
                      <span :class="[field.titleClass ?? titleClass]">{{field.title ?? ''}} </span> <span :class="[field.valueClass ?? valueClass]">{{getProperty(item,field.key)}}</span>
                    </template>
                    <!-- obtener titulo y valor con key normal -->
                    <template v-else-if="item[field.key]">
                      <span name="les" :class="[field.titleClass ?? titleClass]">{{field.title ?? ''}} </span> <span :class="[field.valueClass ?? valueClass]"> {{field.format ? field.format(item) : item[field.key]}}</span>
                    </template>
                  </div>
                  </slot>
              </template>
            </template>
          </div>
          <div v-if="cardSource.lastBlock" :class="[`${cardSource.lastBlock.mainClass ?? 'flex justify-end w-px-30 bg-blue-'}`]" >
            <slot name="lastBlock" v-bind="{item,i}">
               <font-awesome-icon @click.stop="handleLastBlock(item,i)" class="w-full cursor-pointer" icon="ellipsis-vertical" style="font-size:18px;" />
            </slot>
          </div>
        </div>
        <div v-if="loading" class="flex justify-center my-2">
          <svg  class="animate-spin w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>

</template>

<script>
// import debounce from '@/services/Utils/Debounce';
import getProperty from '@/services/Utils/Property';
import { getCoords } from '@/services/Utils/Doom';

// {
//   lastBlock: { si no quieres hacer nada con el ultimo bloque, simplemente no envies este objeto
//     mainCLass: 'clase-1' para configurar una clase
//     slot: true para indicar que el ultimo bloque es un slot
//   },
//   field:[
//     {
//       key: 'name' la key a buscar en el objeto, LE PUEDES PASAR UN OBJETO ANIDADO EJ: COUNTRY.PROVINCE.NAME
//       valueClass: 'clase-para-value' // tiene por defecto una clase
//       title: 'Nombre',
//       titleClass : 'clase-para-titulo' // tiene por defecto una clase
//       onlyTitle: true Si solo quieres mostrar el titulo , si no, no pases la propiedad
//       colsField: 'c-col-s-12' la cantidad de columnas que abarca, tiene valor por defecto,
//       condition: 'function', puedes pasarle una funcion para evaluar la condicion para que se muestre esta propiedad,
//       format: 'function' para formatear el texto
//     }
//   ]
// }

export default {
  props: {
    cardSource: {
      type: [Array, Object],
    },
    response: {
      type: [Object, Array],
    },
    cardPadding: {
      type: String,
      default: 'p-2',
    },
    colsField: {
      type: String,
      default: 'c-col-s-12',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    countVisible: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: 'white',
    },
    titleClass: {
      type: String,
      default: 'font-bold',
    },
    valueClass: {
      type: String,
      default: 'text-gray-500',
    },
  },
  emits: ['lowerLimit', 'clickCard', 'clickCardLastBlock'],
  mounted() {
    if (this.cardSource.windowParent) {
      window.addEventListener('scroll', this.scrollCard);
      if (this.cardSource.minHeight) {
        this.minHeight = this.cardSource.minHeight;
        if (!this.cardSource.maxHeight) {
          this.maxHeight = `${this.cardSource.minHeight}`;
        }
      } else if (this.cardSource.windowInnerHeight) {
        this.maxHeight = `${this.cardSource.windowInnerHeight - getCoords(this.$refs.refCardMain).top}px`;
        // alert(this.cardSource.windowInnerHeight);
      } else {
        this.minHeight = `${window.innerHeight}px`;
        this.maxHeight = `${window.innerHeight}px`;
      }
    } else {
      if (this.cardSource.minHeight) {
        this.minHeight = this.cardSource.minHeight;
        if (!this.cardSource.maxHeight) {
          this.maxHeight = `${this.cardSource.minHeight}`;
        }
      } else if (this.cardSource.windowInnerHeight) {
        this.maxHeight = `${this.cardSource.windowInnerHeight - getCoords(this.$refs.refCardMain).top}px`;
        // alert(this.cardSource.windowInnerHeight);
      } else {
        this.minHeight = `${window.innerHeight - getCoords(this.$refs.refCardMain).top}px`;
        this.maxHeight = `${window.innerHeight - getCoords(this.$refs.refCardMain).top}px`;
      }

      // const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      this.$refs.refCardMain.addEventListener('scroll', this.scrollCard);
      //
    }
  },
  data() {
    return {
      scrollTopCardWrapper: null,
      clientHeightCardWrapper: null,
      scrollHeightCardMain: null,
      parentPending: false,
      maxHeight: '200px',
      minHeight: '200px',
    };
  },
  methods: {
    getValue(item, field) {
      return item[field.key];
    },
    scrollCard() {
      const CardMain = this.$refs.refCardMain;
      if (CardMain) {
        const wrapper = this.getScrollParent(this.$refs.refCardMain);
        // desplazamiento superior
        const scrollTopCardWrapper = wrapper.scrollTop;
        this.scrollTopCardWrapper = Math.ceil(scrollTopCardWrapper);
        // altura de compensación para la envoltura de las cartas
        const clientHeightCardWrapper = wrapper.clientHeight;
        this.clientHeightCardWrapper = clientHeightCardWrapper;

        const scrollHeightCardMain = wrapper.scrollHeight;
        this.scrollHeightCardMain = scrollHeightCardMain;
        const percent = (this.clientHeightCardWrapper * 10) / 100;
        // si el desplazamiento superior de la envoltura mas la altura de compensacion de envoltura es igual a la altura de compensacion de la tabla
        if ((this.scrollTopCardWrapper + this.clientHeightCardWrapper) + Math.ceil(percent) >= this.scrollHeightCardMain) {
          this.$emit('lowerLimit');
        }
      }
    },
    getScrollParent(node) {
      if (node == null) {
        return null;
      }
      if (node.scrollHeight > node.clientHeight) {
        return node;
      }
      return this.getScrollParent(node.parentNode);
    },
    handleLastBlock(item, index) {
      this.$emit('clickCardLastBlock', { item, index });
    },
  },

  setup() {
    return { getProperty };
  },
};
</script>

<style>

.main-base-card{
  position: relative;
  display:flex;
  flex-direction: column;
  overflow-y:auto;
  /* height:600px; */
}

.main-base-card::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.main-base-card::-webkit-scrollbar-thumb {
    background: #cfcece;
    -webkit-border-radius: 1ex;
    /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); */
}

.baseCard{
  display: flex;
  flex-direction: row;
  /* display:grid;
  grid-template-columns: repeat(12, minmax(0, 1fr)); */
}

.content-card{
  display:grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  cursor:pointer;
}

/* .baseCardStyle{ */
  /* box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); */
/* } */

</style>
