'strict mode';

import AuthenticationRepository from '@/repositories/Authentication/AuthenticationRepository';
import { objEmpty } from '@/services/Utils/Objects';

const checkToken = async (to, from, router, store) => {
  const getToken = localStorage.getItem('token');
  let secondRedirectUrl = encodeURIComponent(`${process.env.VUE_APP_ADMIN_CONTROLADOR}?token=&redirectTo=${to.fullPath}`);
  let url = `${process.env.VUE_APP_OPERACIONES_LOGIN}?redirectTo=${secondRedirectUrl}`;
  if (!getToken) {
    window.location.href = url;
  }
  if (objEmpty(store.state.auth.user)) {
    store.dispatch('auth/setPropAuth', { key: 'loadingAuth', value: true });
    await AuthenticationRepository.checkToken()
      .then(((res) => {
        const { user, token, abilities } = res.data;
        if (token) {
          localStorage.setItem('token', token);
          store.dispatch('auth/setPropAuth', { key: 'token', value: token });
        }
        store.dispatch('auth/setUser', user);
        if (abilities) {
          store.dispatch('auth/setPropAuth', { key: 'abilities', value: abilities });
        }
      }))
      .catch(() => false)
      .finally(() => {
        store.dispatch('auth/setPropAuth', { key: 'loadingAuth', value: false });
      });
  }
};

export default checkToken;
