'strict mode';

import axios from 'axios';
import baseUrl from '@/services/Api/BaseUrl';

const $http = axios.create({
  baseURL: baseUrl.apiDevuelvoya,
});

/* eslint no-param-reassign: ["error", { "props": false }] */
$http.interceptors.request.use(
  // Do something before request is sent
  // config.headers.common['interceptorheader'] = 'Interceptor Header';
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
      return config;
    }
    return config;
  },
  (error) => Promise.reject(error),
  // Do something with request error
);

// Any status code that lie within the range of 2xx cause this function to trigger
// Do something with response data

$http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('NO AUTENTICADO');
      localStorage.removeItem('token');
      document.location.href = '/401';
      // window.history.pushState('data', 'title', '/');
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default $http;
