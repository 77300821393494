const Coverages = () => import(/* webpackChunkName: "Admin" */ '@/views/Coverage/Coverages.vue');
const NewCoverage = () => import(/* webpackChunkName: "Admin" */ '@/views/Coverage/NewCoverage.vue');
const EditCoverage = () => import(/* webpackChunkName: "Admin" */ '@/views/Coverage/EditCoverage.vue');
const ExchangeCoverage = () => import(/* webpackChunkName: "Admin" */ '@/views/Coverage/ExchangeCoverage.vue');
const DeleteCoverage = () => import(/* webpackChunkName: "Admin" */ '@/views/Coverage/DeleteCoverage.vue');
const EmptyAreas = () => import(/* webpackChunkName: "Admin" */ '@/views/Coverage/EmptyAreas.vue');

const CoverageRoute = [
  {
    path: '/coverages/:q*',
    name: 'Coverages',
    component: Coverages,
    meta: { gate: 'coverage_index', requiresAuth: true },
    children: [
      {
        path: 'new',
        name: 'newCoverage',
        component: NewCoverage,
        meta: { gate: 'coverage_store' },
      },
      {
        path: 'edit/:id',
        name: 'editCoverage',
        component: EditCoverage,
        meta: { gate: 'coverage_update' },
      },
      {
        path: 'transfer',
        name: 'transferCoverage',
        component: ExchangeCoverage,
        meta: { gate: 'coverage_update' },
      },
      {
        path: 'copy',
        name: 'copyCoverage',
        component: ExchangeCoverage,
        meta: { gate: 'coverage_update' },
      },
      {
        path: 'delete',
        name: 'deleteCoverage',
        component: DeleteCoverage,
        meta: { gate: 'coverage_update' },
      },

    ],
  },
  {
    path: '/emptyAreas/:q*',
    name: 'EmptyAreas',
    component: EmptyAreas,
    meta: { gate: 'coverage_index', requiresAuth: true },
  },
];
export default CoverageRoute;
