import { OPERATOR, COLLECTOR, COMMERCE } from '@/services/Utils/Role';

const allowedByVehicle = ['country_id', 'firstName', 'patent', 'frontDocument', 'backDocument', 'taxPayer', 'proofOfAddress', 'avatar', 'schedules'];
const allowedWithOutVehicle = ['country_id', 'firstName', 'frontDocument', 'backDocument', 'taxPayer', 'proofOfAddress', 'avatar', 'schedules'];
const allowedCommerce = ['country_id', 'firstName', 'frontDocument', 'backDocument', 'taxPayer', 'proofOfAddress', 'avatar', 'local', 'schedules'];

export default {
  namespaced: true,
  state: {
    documents: [
      {
        title: 'Ubicacion',
        completed: false,
        key: 'country_id',
        namePath: 'Location',
        progress: 12.5,
      },
      {
        title: 'Mi perfil',
        completed: false,
        key: 'firstName',
        namePath: 'Profile',
        progress: 12.5,
      },
      {
        title: 'Mi vehiculo',
        completed: false,
        key: 'patent',
        namePath: 'Vehicle',
        progress: 12.5,
      },
      {
        title: 'Documento frontal',
        completed: false,
        key: 'frontDocument',
        namePath: 'FrontDocument',
        progress: 12.5,
      },
      {
        title: 'Documento dorsal',
        completed: false,
        key: 'backDocument',
        namePath: 'BackDocument',
        progress: 12.5,
      },
      {
        title: 'Contribuyente',
        completed: false,
        key: 'taxPayer',
        namePath: 'TaxPayer',
        progress: 12.5,
      },
      {
        title: 'Constancia domicilio',
        completed: false,
        key: 'proofOfAddress',
        namePath: 'ProofOfAdress',
        progress: 12.5,
      },
      {
        title: 'Foto perfil',
        completed: false,
        key: 'avatar',
        namePath: 'Avatar',
        progress: 12.5,
      },
      {
        title: 'Foto comercio',
        completed: false,
        key: 'local',
        namePath: 'Store',
        progress: 12.5,
      },
      {
        title: 'Horarios disponibles',
        completed: false,
        key: 'schedules',
        namePath: 'Schedules',
        progress: 12.5,
      },
    ],
    progress: 0,
  },
  mutations: {
    SET_COMPLETE_DOC(state, payload) {
      state.documents[payload].completed = true;
    },
    SET_PROGRESS(state, payload) {
      state.progress = payload;
    },
    SET_PROGRESS_CALCULATION(state, payload) {
      state.progress = payload;
    },
    RESET_DOC(state, payload) {
      state.documents[payload].completed = false;
    },
    SET_DOCUMENTS(state, payload) {
      state.documents = payload;
    },
    SET_DOCUMENT(state, payload) {
      state.documents[payload.index].progress = payload.width;
    },
  },
  actions: {
    async reviewDocuments({ state, commit, dispatch }, payload) {
      await dispatch('prepareToRegisterByRole', payload);
      await dispatch('calculateProgressWidthToWorkTool', payload);
      await dispatch('resetDocuments');

      commit('SET_PROGRESS', 0);
      state.documents.forEach((document, index) => {
        if (Object.prototype.hasOwnProperty.call(payload, document.key)) {
          if (typeof payload[document.key] === 'object') {
            if (payload[document.key]?.length > 0) {
              commit('SET_COMPLETE_DOC', index); // envio el index del que ya esta completo
              const progress = (state.progress + document.progress);
              commit('SET_PROGRESS', progress);
            }
          }

          if (payload[document.key] && (typeof payload[document.key] === 'string' || typeof payload[document.key] === 'number')) {
            if (payload[document.key] && payload[document.key] !== null && payload[document.key] !== '') {
              commit('SET_COMPLETE_DOC', index); // envio el index del que ya esta completo
              const progress = (state.progress + document.progress);
              commit('SET_PROGRESS', progress);
            }
          }
        }
      });
      return true;
      // review completed data to update object and show completed steps
    },
    resetDocuments({ state, commit }) {
      return new Promise((resolve) => {
        state.documents.forEach((document, index) => {
          commit('RESET_DOC', index);
        });
        resolve(true);
      });
    },
    calculateProgressWidthToWorkTool({ state, commit }, payload) {
      return new Promise((resolve) => {
        const { id } = payload.role;
        let width = 0;
        if (OPERATOR(id)) width = 12.5;

        if (COLLECTOR(id)) {
          if (payload.tool === 'bicicleta') {
            width = 12.5;
          } else {
            width = 11.1;
          }
        }

        if (COMMERCE(id)) width = 11.1;

        const { documents } = state;
        documents.forEach((doc, index) => {
          const newProgress = { index, width };
          commit('SET_DOCUMENT', newProgress);
        });
        resolve(documents);
      });
    },
    prepareToRegisterByRole({ commit, state }, payload) {
      return new Promise((resolve) => {
        const { id } = payload.role;
        let originalData = state.documents;
        if (OPERATOR(id)) {
          originalData = originalData.filter((doc) => allowedWithOutVehicle.find((allowed) => allowed === doc.key));
        }
        if (COLLECTOR(id)) {
          if (payload.tool === 'bicicleta') {
            originalData = originalData.filter((doc) => allowedWithOutVehicle.find((allowed) => allowed === doc.key));
          } else {
            originalData = originalData.filter((doc) => allowedByVehicle.find((allowed) => allowed === doc.key));
          }
        }
        if (COMMERCE(id)) {
          originalData = originalData.filter((doc) => allowedCommerce.find((allowed) => allowed === doc.key));
        }
        commit('SET_DOCUMENTS', originalData);
        resolve(originalData);
      });
    },
    isFinished({ state }) {
      const isFinished = state.documents.every((doc) => doc.completed);
      if (isFinished) {
        return true;
      }
      return false;
    },
  },
};
