import EntryRoute from '@/router/Home/entryRoute';

const App = () => import(/* webpackChunkName: "Home" */ '@/views/Home/App.vue');
const HomeRoute = [

  {
    path: '/',
    component: App,
    children: [
      ...EntryRoute,
    ],
  },

];

export default HomeRoute;
