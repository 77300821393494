<template>
    <div>
      <LoaderDoom
      v-if="loadingAuth || (loadingUser && booted)"
      LoaderDoomClass="LoaderDoom"
      :image="true"
      />
      <Sidebar> <BarAdmin  /> </Sidebar>
      <div class="main-app">
          <router-view v-slot="{ Component, route }">
            <!--:key="route.matched[0].name" -->
              <transition :name="route.meta.transitionName ? route.meta.transitionName : ''" mode="out-in">
                  <component :is="Component"  />
              </transition>
          </router-view>
      </div>
  </div>

</template>

<script setup>


import Sidebar from '@/components/Core/Bar/BaseSidebar.vue';
import LoaderDoom from '@/components/General/LoaderDoom.vue';
import BarAdmin from '@/components/Core/Bar/BarAdmin.vue';
import { useStore } from 'vuex'
import { watch, computed } from 'vue'
import { useI18n } from 'vue-i18n';

const store = useStore()
const loadingAuth = computed(() => store.state.auth.loadingAuth)
const loadingUser = computed(() => store.state.auth.loadingUser)
const booted = computed(() => store.state.auth.booted)
const user = computed(() => store.state.auth.user)

watch(user, (newValue) => {
  changeLocale(newValue)
} , { deep: true})

const { t, locale } = useI18n();

const changeLocale = (userData) => {
  const { configuration } = userData
  if(configuration && configuration.lang){
    locale.value = configuration.lang
  }
}


</script>

<style  lang="css">
@import './assets/css/main.css';

/* base */

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }

  .slide-enter-from,
  .slide-leave-to{
    opacity: 0;
    transform: translateY(-5%);
  }

/* base */

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: opacity 0.4s, transform 0.4s;
  }

  .slide-right-enter-from,
  .slide-right-leave-to{
    opacity: 0;
    transform: translateX(30%);
  }

  .slide-left-enter-from,
  .slide-left-leave-to{
    opacity: 0;
    transform: translateX(-30%);
  }

</style>
