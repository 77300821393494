export const ROLE = {
  ADMIN: 1,
  ZONE_OPERATOR: 2,
  OPERATOR: 3,
  COLLECTOR: 4,
  COMMERCE: 5,
  ENTERPRISE: 6,
  TECHNICAL: 7,
};

export const TOOLS = {
  CAR: 1,
  MOTORCYCLE: 2,
  BICYCLE: 3,
  NOTEBOOK: 4,
  PHONE: 5,
  STORE: 6,
};

export const ROLES = [
  { id: 1, name: 'Administrador' },
  { id: 2, name: 'Operador zona' },
  { id: 3, name: 'Operador' },
  { id: 4, name: 'Recolector' },
  { id: 5, name: 'Comercio' },
  { id: 6, name: 'Empresa' },
  { id: 7, name: 'Tecnico' },
];

export const OPERATIONAL_ROLES = [
  { id: 3, name: 'Operador' },
  { id: 4, name: 'Recolector' },
  { id: 5, name: 'Comercio' },
  { id: 7, name: 'Tecnico' },
];

export const ADMIN_ROLES = [
  { id: 1, name: 'Administrador' },
  { id: 2, name: 'Operador zona' },
];

export const ENTERPRISE_ROLES = [
  { id: 6, name: 'EMPRESA' },
];

export const returnRole = (string) => {
  let chose;
  if (string.indexOf('recuperador') > -1) {
    chose = ROLE.COLLECTOR;
  }
  if (string.indexOf('comercio') > -1) {
    chose = ROLE.COMMERCE;
  }
  if (string.indexOf('operador') > -1 || string.indexOf('celular') > -1 || string.indexOf('portatil') > -1) {
    chose = ROLE.OPERATOR;
  }
  return chose;
};

export const ADMIN = (id) => {
  let choose = false;
  if (ROLE.ADMIN === id) choose = true;
  return choose;
};

export const ZONE_OPERATOR = (id) => {
  let choose = false;
  if (ROLE.ZONE_OPERATOR === id) choose = true;
  return choose;
};

export const OPERATOR = (id) => {
  let choose = false;
  if (ROLE.OPERATOR === id) choose = true;
  return choose;
};

export const COLLECTOR = (id) => {
  let choose = false;
  if (ROLE.COLLECTOR === id) choose = true;
  return choose;
};

export const COMMERCE = (id) => {
  let choose = false;
  if (ROLE.COMMERCE === id) choose = true;
  return choose;
};

export const ENTERPRISE = (id) => {
  let choose = false;
  if (ROLE.ENTERPRISE === id) choose = true;
  return choose;
};

export const TECHNICAL = (id) => {
  let choose = false;
  if (ROLE.TECHNICAL === id) choose = true;
  return choose;
};
