const baseUrl = {
  // apiDevuelvoya: 'http://apidevuelvoya.test/',
  // apiDevuelvoya: 'http://192.168.1.37/',
  apiDevuelvoya: process.env.VUE_APP_API,
  // apiDevuelvoya: process.env.VUE_APP_API_NETWORK,

  // TEST-------------------------------------
  // apiDevuelvoyaTest: 'http://apidevuelvoya.test/',
  // apiDevuelvoyaTestImg: 'http://apidevuelvoya.test/',
  // appDevuelvoyaTest: 'http://apidevuelvoya.test/',

};

export default baseUrl;
