const ImportEquipment = () => import(/* webpackChunkName: "Admin" */ '@/views/Sync/ImportEquipment.vue');

const SyncRoute = [
  {
    path: '/sync/equipment',
    name: 'importEquipment',
    component: ImportEquipment,
    meta: { gate: 'coverage_index' },
  },
];
export default SyncRoute;
