const addParamsToRoute = (params, path) => new Promise((resolve) => {
  let query = '';
  const parameters = Object.entries(params);
  if (parameters.length > 0) {
    parameters.forEach((route, index) => {
      if (index === 0) {
        query += `?${route[0]}=${route[1]}`;
      } else if (index + 1 === parameters.length) {
        query += `&${route[0]}=${route[1]}`;
      } else {
        query += `&${route[0]}=${route[1]}`;
      }
    });
    window.history.replaceState(
      {},
      null,
      `${path}${query}`,
    );
  } else {
    window.history.replaceState(
      {},
      null,
      `${path}${query}`,
    );
  }

  resolve(true);
});
  // if (params) this.$router.replace({ query: params });

export { addParamsToRoute };
