import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSignaturePad from 'vue-signature-pad';
import { SetupCalendar } from 'v-calendar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/assets/css/tailwind.css';
import baseUrl from '@/services/Api/BaseUrl';

import BaseModal from '@/components/Core/Modal/BaseModal.vue';
import NavbarSection from '@/components/Core/Navbar/NavbarSection.vue';
import CardExpress from '@/components/Core/Card/CardExpress.vue';
import TableExpress from '@/components/Core/Table/TableExpress.vue';
import Column from '@/components/Core/Table/Column.vue';
import FormExpress from '@/components/Form/FormExpress.vue';
import BaseButton from '@/components/Form/BaseButton.vue';
import BaseSelect from '@/components/Form/BaseSelect.vue';
import BaseInput from '@/components/Form/BaseInput.vue';
import BaseFile from '@/components/Form/BaseFile.vue';
import SimpleAlert from '@/components/General/SimpleAlert.vue';
//
import Confirm from '@/components/General/Confirm.vue';
import BaseCard from '@/components/Core/Card/BaseCard.vue';
import { icons } from './assets/icons/fontAwesome';
import i18n from '@/assets/lang/translations'


//

// mixin

library.add(icons);
const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('BaseModal', BaseModal)
  .component('NavbarSection', NavbarSection)
  .component('CardExpress', CardExpress)
  .component('TableExpress', TableExpress)
  .component('Column', Column)
  .component('FormExpress', FormExpress)
  .component('BaseSelect', BaseSelect)
  .component('BaseInput', BaseInput)
  .component('BaseFile', BaseFile)
  .component('BaseButton', BaseButton)
  .component('SimpleAlert', SimpleAlert)
  .component('BaseCard', BaseCard)
  .component('Confirm', Confirm)
  .use(VueToast)
  .use(VueSignaturePad)
  .use(SetupCalendar, {})
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueAxios, axios)
  .mount('#app');
app.$.appContext.config.globalProperties.$apiDevuelvoya = baseUrl.apiDevuelvoya;
// app.config.globalProperties.$apiDevuelvoya = baseUrl.apiDevuelvoya;
