'strict mode';

const Unauthenticated = async (to, from, router, store) => {
//   const { auth } = store;
  const auth = store.getters['auth/user'];
  const token = localStorage.getItem('token');
  if (token || (auth && Object.keys(auth).length > 0)) {
    // aca limpio el token y el usuario para que loguee nuevamente,
    // si vino para aca es porque quiere necesitar loguear
    store.dispatch('auth/removeToken');
    store.dispatch('auth/setUser', {});
    router.push({ name: 'Login' });
  }
};

export default Unauthenticated;
