<template>
  <div>
      <div class="flex flex-row items-center">
        <div @click="select()" :class="[`${switchClass}`]">
          <div  :class="[`${switchh}`]"></div>
        </div>
        <div class="ml-2">
          {{label}}
        </div>
       </div>
      <!-- <div @click="select()" class="bg-blue-200"> click</div> -->
  </div>
</template>

<script>
import { objEmpty } from '@/services/Utils/Objects';

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [Object, String, Number, Array, Boolean],
    },
    value: {
      type: [Object, String, Number, Array],
    },
    label: {
      type: [Object, String, Number, Array],
    },
    trackBy: {
      type: String,
      default: 'id',
    },
  },
  mounted() {
    this.handlerValue();
  },
  data() {
    return {
      check: false,
    };
  },
  methods: {
    select() {
      try {
        if (!this.check) {
          if (!this.value) {
            // si el value es undefined, devuelvo un boolean
            this.$emit('update:modelValue', true);
            // si no, empiezo a llenar el array con el value
          } else if (this.modelValue && (!objEmpty(this.modelValue) || this.modelValue.length > 0)) {
            if (typeof this.modelValue === 'object') {
              if (Array.isArray(this.modelValue)) {
                const storage = [...this.modelValue];
                if (typeof this.value === 'object') {
                  if (Array.isArray(this.value)) {
                    storage.push(this.value[0]);
                  } else {
                    storage.push(this.value);
                  }
                } else {
                  storage.push(this.value);
                }

                this.$emit('update:modelValue', storage);
              } else {
                const storage = this.value;
                this.$emit('update:modelValue', storage);
              }
            } else {
              const storage = this.value;
              this.$emit('update:modelValue', storage);
            }
          } else if (typeof this.value === 'object') {
            if (Array.isArray(this.value)) {
              const storage = [...this.value];
              this.$emit('update:modelValue', storage);
            } else {
              const storage = this.value;
              this.$emit('update:modelValue', storage);
            }
          } else if (typeof this.modelValue === 'object' && Array.isArray(this.modelValue)) {
            // como no entro en ninguna de las anteriores, el valor es un boolean|number|string, y debo armar el v-model segund el typo de v.model
            const storage = [...this.modelValue];
            storage.push(this.value);
            this.$emit('update:modelValue', storage);
          } else {
            let storage = this.value;
            storage = this.value;
            this.$emit('update:modelValue', storage);
          }
        } else if (typeof this.value === 'object') {
          // aca trato de eliminar el value (array || object) del modelValue

          if (Array.isArray(this.modelValue)) {
            const storage = [...this.modelValue];
            storage.splice(this.modelValue.findIndex((val) => this.checkValue(val)), 1);
            this.$emit('update:modelValue', storage);
          } else {
            this.$emit('update:modelValue', {});
          }
        } else if (typeof this.value === 'number' || typeof this.value === 'string') {
        // aca trato de eliminar el value  del modelValue
          if (Array.isArray(this.modelValue)) {
            const storage = [...this.modelValue];
            storage.splice(this.modelValue.findIndex((val) => val === this.value), 1);
            this.$emit('update:modelValue', storage);
          } else {
            this.$emit('update:modelValue', null);
          }
        } else if (typeof this.modelValue === 'boolean' && !this.value) {
        // aca si el modelvalue es un boolean y this.value sigue undefined, seteo un boolean
          this.$emit('update:modelValue', false);
        }
        // this.check = !this.check;
      } catch (err) {
        this.$toast.info('Switch sin valor', {
          duration: 1500,
          dismissible: true,
          position: 'top',
        });
        // console.error('ASEGURATE DE DEFINIR EL optionValue O VALUE, PARA QUE EL SWITCH SEPA QUE DEVOLVER: BOOLEAN, OBJECT,ARRAY');
      }
    },

    checkValue(val) {
      if (typeof this.value === 'object') {
        if (val[this.trackBy] && val[this.trackBy] === this.value[this.trackBy]) {
          return true;
        }
        if (val === this.value[0]) {
          return true;
        }
        return false;
      }

      return val && val === this.value;
    },
    handlerValue() {
      // aca se usa usualmente para el llenado masivo/autocompletado
      if (typeof this.value === 'object') {
        // busco en el modelvalue (array/object) a medida que lo recorro, busco el valor del switch en el array actual, para
        // setear el check
        if (Array.isArray(this.modelValue)) {
          if (this.modelValue.some((s) => this.checkValue(s))) {
            if (!this.check) {
              this.check = true;
            }
          } else {
            this.check = false;
          }
        } else if (this.modelValue && !objEmpty(this.modelValue)) {
          this.check = true;
        } else {
          this.check = false;
        }
      } else if (typeof this.value === 'number' || typeof this.value === 'string') {
        if (Array.isArray(this.modelValue)) {
          if (this.modelValue.includes(this.value)) {
            if (!this.check) {
              this.check = true;
            }
          } else {
            this.check = false;
          }
        } else if (this.modelValue) {
          this.check = true;
        } else {
          this.check = false;
        }
        // aca llegaria cuando el value es undefined o null, por lo que deberiamos considerar que quiere setearlo como true o false (boolean)
      } else if (typeof this.modelValue === 'boolean') {
        if (this.modelValue) {
          this.check = true;
        } else {
          this.check = false;
        }
      }
    },
  },
  computed: {
    switchClass() {
      if (this.check) {
        return 'slot-switch-check';
      }
      return 'slot-switch';
    },
    switchh() {
      if (this.check) {
        return 'switch-check';
      }
      return 'switch';
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.handlerValue();
      },
      deep: true,
    },
  },
};
</script>

<style>

.slot-switch{
  position: relative;
  width: 2.2rem;
  height:0.9rem;
  /* background: #1867c0; */
  background: #bec1c4;
  border-radius: 10px;
  transition: all .6s;
  cursor: pointer;
}

.slot-switch-check{
  position: relative;
  width: 2.2rem;
  height:0.9rem;
  /* background: #1867c0; */
  background: #5894d8;
  border-radius: 10px;
  transition: all .6s;
  cursor: pointer;
}

.switch{
  position: absolute;
  bottom: -2.22px;
  width: 1.25rem;
  height:1.25rem;
  left:-4px;
  background: #fdfdfd;
  border-radius: 100%;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
  transition: all .6s;
}

.switch:hover{
  box-shadow: 1px 2px 6px rgba(0,0,0,0.7);
}

.switch-check{
  position: absolute;
  bottom: -2.22px;
  width: 1.25rem;
  height:1.25rem;
  right: -2px;
  background: #2369b9;
  border-radius: 100%;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
  transition: all .6s;
}

</style>
