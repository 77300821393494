const Agreements = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Agreements.vue');
const Customers = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Customers.vue');
const AgreeNew = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/AgreeNew.vue');
const AgreeEdit = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/AgreeEdit.vue');
const Equipments = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Equipments.vue');
const Options = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Options.vue');
const Visit = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Visit.vue');
const Commerce = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Commerce.vue');
const Point = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Point.vue');
//
const Confirm = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Confirm.vue');
const Coupon = () => import(/* webpackChunkName: "Admin" */ '@/views/Agreement/Steps/Coupon.vue');
//

const AgreementRoute = [
  {
    path: '/agreements/:q*',
    name: 'agreements',
    component: Agreements,
    meta: { gate: 'agreement_index', requiresAuth: true },
    children: [
      {
        path: ':agreement_id/:identificacion/edit',
        name: 'agreeFirstStepEdit',
        component: AgreeEdit,
        children: [
          {
            path: 'equipments',
            name: 'agreeSecondStepEdit',
            component: Equipments,
          },
          {
            path: 'options',
            name: 'agreeThirdStepEdit',
            component: Options,
          },
          // el cuarto paso puede ser visita, comercio o vap
          {
            path: 'visit',
            name: 'agreeFourthStepVisitEdit',
            component: Visit,
          },
          {
            path: 'commerce',
            name: 'agreeFourthStepCommerceEdit',
            component: Commerce,
          },
          {
            path: 'point',
            name: 'agreeFourthStepPointEdit',
            component: Point,
          },
          {
            path: 'confirm',
            name: 'confirmAgreeEdit',
            component: Confirm,
          },
        ],
      },
    ],
  },
  {
    path: '/agree/customers',
    name: 'agreeCustomer',
    component: Customers,
    meta: { gate: 'agreement_store', requiresAuth: true },
    children: [
      {
        path: 'process/:identificacion/:matchOriginalAdress',
        name: 'agreeFirstStep',
        component: AgreeNew,
        children: [
          {
            path: 'equipments',
            name: 'agreeSecondStep',
            component: Equipments,
          },
          {
            path: 'options',
            name: 'agreeThirdStep',
            component: Options,
          },
          // el cuarto paso puede ser visita, comercio o vap
          {
            path: 'visit',
            name: 'agreeFourthStepVisit',
            component: Visit,
          },
          {
            path: 'commerce',
            name: 'agreeFourthStepCommerce',
            component: Commerce,
          },
          {
            path: 'point',
            name: 'agreeFourthStepPoint',
            component: Point,
          },
          {
            path: 'confirm',
            name: 'confirmAgree',
            component: Confirm,
          },
        ],
      },
    ],
  },
  {
    path: '/agree/coupon/:email?/:prefix?/:phoneNumber?/:agreement_id?',
    name: 'agreeCoupon',
    meta: {  requiresAuth: true },
    component: Coupon,
  },
  {
    path: '/agree/coupon/edit/:email?/:prefix?/:phoneNumber?/:agreement_id?',
    name: 'agreeCouponEdit',
    meta: {  requiresAuth: true },
    component: Coupon,
  },

];
export default AgreementRoute;
