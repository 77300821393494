export default {
  namespaced: true,
  state: {
    areaCodes: [],
    countries: [],
    enterprises: [],
    recommendations: [],
    unsubscribed_motives: [],
    equipment_states: [],
    contact_channels: [],
    agreement_states: [],
    agreement_types: [],
    schedules: []
  },
  actions: {
    setPropUtility({ state }, payload) {
      return new Promise((resolve) => {
        if (payload.index) {
          if (payload.prop) {
            state[payload.key][payload.index][payload.prop] = payload.value;
            resolve(state[payload.key][payload.index][payload.prop]);
          } else {
            state[payload.key][payload.index] = payload.value;
            resolve(state[payload.key][payload.index]);
          }
        } else {
          state[payload.key] = payload.value;
          resolve(state[payload.key]);
        }
      });
    },
  },

};
