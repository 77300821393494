<template>
      <transition name="fade" mode="out-in">
        <div v-if="rightBar" :class="['filter' ]" >
            <slot name="contentBar" ></slot>
        </div>
      </transition>
</template>

<script>
export default {
  props: {
    rightBar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

.filter {
    position:absolute;
    right: 0%;
    background:rgb(255, 255, 255);
    width:15rem;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    user-select: none;
    transition: .5s ease-in-out;
    display: flex;
    flex-direction: column;
    z-index: var(--z-6);
    border-right: 1px solid rgb(241, 236, 236);

}

@media (min-width: 520px){
  .filter {
    min-width: 17rem;
  }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.fade-enter-from, .fade-leave-to{
    opacity:0;
    transform: translateX(-10%);
}

</style>
