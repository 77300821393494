import { createStore } from 'vuex';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import auth from '@/modules/Authentication/Auth';
import register from '@/modules/Authentication/Register';
import sidebar from '@/modules/Core/Sidebar';
import utilities from '@/modules/Core/Utilities';
import agreement from '@/modules/Core/Agreement';
import equipments from '@/modules/Core/Equipments';
//

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    register,
    sidebar,
    utilities,
    agreement,
    equipments,
  },
});
