const Dashboard = () => import(/* webpackChunkName: "Admin" */ '@/views/Dashboard/Dashboard.vue');
// QUITAR META PARA NOR EPERTIR LOGICA Y SOLO REVISAR EL TOKEN
const DashboardRoute = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { gate: 'dashboard_access', abilities: true, requiresAuth: true },
  },
];
export default DashboardRoute;
