<!-- eslint-disable max-len -->
<template>
    <div :class="LoaderDoomClass">
        <svg v-if="!image"  :class="spinnerClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <div class="flex justify-center items-center" v-else>
           <img style="width:80%;" :src="`${$apiDevuelvoya}/images/logo/devuelvoyexpress.png`" >
        </div>
    </div>
</template>
<script>

// @group Loader
export default {
  props: {
    LoaderDoomClass: {
      type: String,
      default: 'LoaderDoom',
    },
    spinnerClass: {
      type: String,
    },
    image: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
    .LoaderDoom{
        height:100vh;
        display: flex;
        justify-content: center;
        position:fixed;
        width:99%;
        transition: all .2s;
        z-index: var(--z-1);
        background:white;
    }
</style>
