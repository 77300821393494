// import { abilities } from '@/middleware/Authentication/authentication';
//
// const Users = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Users.vue');
// const Admins = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Admins.vue');
// const Enterprises = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Enterprises.vue');
// const Enterprise = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Enterprise.vue');

// const Location = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Location.vue');
// const LocationMap = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/LocationMap.vue');
// const Profile = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Profile.vue');
// const Vehicle = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Vehicle.vue');
// const FrontDocument = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/FrontDocument.vue');
// const BackDocument = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/BackDocument.vue');
// const TaxPayer = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/TaxPayer.vue');
// const ProofOfAddress = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/ProofOfAddress.vue');
// const Store = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Store.vue');
// const Avatar = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Avatar.vue');
// const Schedules = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Schedules.vue');
// const Contract = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Contract.vue');
// const PaymentAccounts = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/PaymentAccounts.vue');
// const Credentials = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/Credentials.vue');
// const State = () => import(/* webpackChunkName: "Admin" */ '@/views/User/Profile/State.vue');

const UsersRoute = [
  // {
  //   path: '/users/:q*',
  //   name: 'Users',
  //   component: Users,
  //   meta: { gate: 'user_index', requiresAuth: true },
  //   children: [
  //     {
  //       path: 'location/edit/:id',
  //       component: Location,
  //       name: 'locationEdit',
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'locationMap/edit/:id',
  //       component: LocationMap,
  //       name: 'locationMap',
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'profile/edit/:id',
  //       component: Profile,
  //       name: 'profileEdit',
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'vehicle/edit/:id',
  //       component: Vehicle,
  //       name: 'vehicleEdit',
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'documento-frontal/edit/:id',
  //       component: FrontDocument,
  //       name: 'frontDocumentEdit',
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'documento-dorsal/edit/:id',
  //       component: BackDocument,
  //       name: 'backDocumentEdit',
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'contribuyente/edit/:id',
  //       name: 'taxPayerEdit',
  //       component: TaxPayer,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'domicilio/edit/:id',
  //       name: 'proofOfAddressEdit',
  //       component: ProofOfAddress,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'comercio/edit/:id',
  //       name: 'storeEdit',
  //       component: Store,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'avatar/edit/:id',
  //       name: 'avatarEdit',
  //       component: Avatar,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'horarios/edit/:id',
  //       name: 'schedulesEdit',
  //       component: Schedules,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'contrato/view/:id',
  //       name: 'contractView',
  //       component: Contract,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'cuentas-de-pago/detail/:id',
  //       name: 'paymentAccoutDetail',
  //       component: PaymentAccounts,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'credencial/detail/:id',
  //       name: 'credentialsDetail',
  //       component: Credentials,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: 'estado/:id',
  //       name: 'state',
  //       component: State,
  //       meta: { gate: 'user_update' },
  //     },

  //   ],
  // },
  // {
  //   path: '/admins/:q*',
  //   name: 'Admins',
  //   component: Admins,
  //   meta: { gate: 'user_index', requiresAuth: true },
  // },
  // {
  //   path: '/enterprises/:q*',
  //   name: 'Enterprises',
  //   component: Enterprises,
  //   // beforeEnter: abilities,
  //   meta: { gate: 'user_index' , requiresAuth: true },
  //   children: [
  //     {
  //       path: 'new',
  //       component: Enterprise,
  //       name: 'newEnterprise',
  //       props: true,
  //       meta: { gate: 'user_update' },
  //     },
  //     {
  //       path: ':id/edit',
  //       component: Enterprise,
  //       name: 'editEnterprise',
  //       props: true,
  //       meta: { gate: 'user_update' },
  //     },
  //   ],
  // },
];
export default UsersRoute;
