function smoothScroll(element, nextTick, addSpace = 10, parentElement = window) {
  const mapDoom = element;
  const anchor = document.querySelector(mapDoom);
  let addedSpace = 0;
  if (addSpace !== 10) addedSpace = addSpace;
  else addedSpace = addSpace;

  nextTick(() => {
    parentElement.scroll({
      top: Math.ceil(anchor.scrollHeight + addedSpace),
      behavior: 'smooth',
    });
  });
}
function innerDimensions(node) {
  const computedStyle = getComputedStyle(node);

  let width = node.clientWidth; // width with padding
  let height = node.clientHeight; // height with padding

  height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
  width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
  return { height, width };
}

function getCoords(elem) { // crossbrowser version
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export { smoothScroll, innerDimensions, getCoords };
