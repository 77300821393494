import AuthRoute from '@/router/Authentication/authRoute';
//

const App = () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication/App.vue');
const AuthenticationRoute = [

  {
    path: '/authentication',
    component: App,
    children: [
      ...AuthRoute,
    ],
  },

];

export default AuthenticationRoute;
