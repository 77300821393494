<template>
  <div :class="['navbar shadow-sm', `c-bg-${background}`, `c-text-${titleColor}`, 'border-b border-gray']">
   <div class="flex flex-row">
     <template v-if="back">
        <router-link :to="backPath" class="ml-4">
          <font-awesome-icon :class="[`c-text-${iconColor}`]"  icon="arrow-left" />
        </router-link>
     </template>
     <template v-if="activeBar && activeBarStart">
        <template v-if="!openSidebar">
          <div @click="setBar()" class="ml-2 cursor-pointer">
            <font-awesome-icon :class="[`c-text-${iconColor}`]" icon="bars" />
          </div>
        </template>

     </template>

    <div :class="titleClass">
        {{section}}
    </div>
   </div>
   <template v-if="activeBar && !activeBarStart">
    <div @click="setBar()" class="mr-4 cursor-pointer">
      <font-awesome-icon :class="[`c-text-${iconColor}`]" icon="bars" />
    </div>
   </template>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  props: {
    // Nombre de la seccion
    section: String,
    // El objeto de la ruta a redirigir
    backPath: Object,
    // Clase del titulo
    titleClass: String,
    background: {
      type: String,
      default() {
        return 'white';
      },
    },
    titleColor: {
      type: String,
      default() {
        return 'black';
      },
    },
    iconColor: {
      type: String,
      default() {
        return 'gray';
      },
    },
    activeBar: {
      type: Boolean,
      default() {
        return true;
      },
    },
    activeBarStart: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // Indicar si el navbar tendra boton de retorno
    back: {
      type: Boolean,
      default() {
        return false;
      },
    },

  },
  computed: {
    ...mapState('sidebar', ['openSidebar']),
  },
  methods: {
    ...mapActions('sidebar', ['setSidebar']),
    setBar() {
      this.setSidebar();
    },
  },
};
</script>

<style scoped>
.navbar {
    padding:5px 5px;
    height:72px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}

</style>
