<template>
  <div>
      <template v-if="title">
          {{title}}
      </template>
      <template v-else>
          {{key}}
      </template>
  </div>
</template>

<script>
// ESTOS SON LOS NOMBRES DE LAS COLUMNAS
export default {
  props: {
    key: String,
    title: String,
  },
};
</script>
