// const Login = () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication/Login/Login.vue');
const RestorePassword = () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication/Login/RestorePassword.vue');
const NewPassword = () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication/Login/NewPassword.vue');
const EmailSent = () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication/Login/EmailSent.vue');
const Logout = () => import(/* webpackChunkName: "Authentication" */ '@/views/Authentication/Login/Logout.vue');

const AuthRoute = [
  // {
  //   path: 'ingresar',
  //   name: 'Login',
  //   meta: {
  //     unauthenticated: true,
  //   },
  //   component: Login,
  // },
  {
    path: 'recuperar-clave',
    name: 'RestorePassword',
    component: RestorePassword,
  },
  {
    path: 'restaurar-clave/:token',
    name: 'NewPassword',
    component: NewPassword,
  },
  {
    path: 'cambiar-clave',
    name: 'changePassword',
    meta: {
      auth: true,
    },
    component: NewPassword,
  },
  {
    path: 'email-enviado',
    name: 'EmailSent',
    component: EmailSent,
  },
  {
    path: 'cerrar-sesion',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/autenticacion/controlador/:params?',
    name: 'autenticacion-controlador',
    component: () => import('@/views/Authentication/ControladorPage.vue'),
  },
];
export default AuthRoute;
