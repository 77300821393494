import { objEmpty, empty, hasLocalStorage } from '@/services/Utils/Objects';
import { AGREEMENT_TYPES, OPTION_TYPES } from '@/services/Utils/Utils';

//

export default {
  namespaced: true,
  state: {
    agree: {
      firstStep: {
        country: {
          value: null,
        },
        province: {
          value: null,
        },
        locality: {
          value: null,
        },
        street: {
          value: '',
        },
        between_streets: {
          value: '',
        },
        observations: {
          value: '',
        },
        lat: {
          value: '',
        },
        lng: {
          value: '',
        },
        postalCode: {
          value: '',
        },
        identificacion: {
          value: '',
        },
        original_address: {
          value: '',
        },

      },
      secondStep: {
        equipments: [],
      },
      thirdStep: {
        agreement_type: null,
        pm: false,
        all: false,
      },
      fourthStep: {
        branch: {
          value: {},
        },
        user: {
          value: {},
        },
        day: {
          value: null,
        },
        schedule: {
          value: {},
        },
        prefix: {
          value: {},
        },
        phoneNumber: {
          value: '',
        },
        email: {
          value: '',
        },
        who_delivers: {
          value: '',
        },
        contact_channel: {
          value: null,
        },
      },
      geocoded: {
        latitude: '',
        longitude: '',
        address: '',
        postalCode: '',
        locality: '',
      },
      options: {},
    },
    customer: null,
    agreement: null,
  },
  mutations: {
    SET_FIRST_STEP(state, payload) {
      Object.keys(payload).forEach((key) => {
        // if (payload[key]) {
        //   state.agree.firstStep[key].value = payload[key].value;
        // }
        if (state.agree.firstStep[key]) {
          state.agree.firstStep[key].value = payload[key].value;
        }
      });
    },
    SET_SECOND_STEP(state, payload) {
      if (payload.equipments) {
        state.agree.secondStep.equipments = payload.equipments;
      }
    //   state.agree = payload;
    },
    SET_THIRD_STEP(state, payload) {
      state.agree.thirdStep.agreement_type = payload.agreement_type;
      state.agree.thirdStep.pm = payload.pm;
      state.agree.thirdStep.all = payload.all;
    //   state.agree = payload;
    },
    SET_FOURTH_STEP(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (state.agree.fourthStep[key]) {
          state.agree.fourthStep[key].value = payload[key].value;
        }
      });
    },
    SET_GEOCODED(state, payload) {
      if (payload.latitude) {
        state.agree.geocoded.latitude = payload.latitude;
      }
      if (payload.longitude) {
        state.agree.geocoded.longitude = payload.longitude;
      }
      if (payload.address) {
        state.agree.geocoded.address = payload.address;
      }
      if (payload.postalCode) {
        state.agree.geocoded.postalCode = payload.postalCode;
      }
      if (payload.locality) {
        state.agree.geocoded.locality = payload.locality;
      }
    },
  },
  getters: {
    completeFirstStep(state) {
      let result = false;
      if (state.agree.firstStep.province.value
        && state.agree.firstStep.locality.value
        && state.agree.firstStep.street.value
        && state.agree.firstStep.between_streets.value
        && state.agree.firstStep.lat.value
        && state.agree.firstStep.lng.value
        && state.agree.firstStep.postalCode.value) {
        result = true;
      }
      return result;
    },
    completeSecondStep(state) {
      return state.agree.secondStep.equipments.length > 0;
    },
    completeThirdStep(state) {
      return !!state.agree.thirdStep.agreement_type;
    },
    completeFourthStep(state) {
      let complete = false;
      if (state.agree.thirdStep.agreement_type === AGREEMENT_TYPES.VISIT) {
        complete = !objEmpty(state.agree.fourthStep.user.value)
                    && state.agree.fourthStep.day.value
                    && state.agree.fourthStep.schedule.value
                    && !objEmpty(state.agree.fourthStep.prefix.value)
                    && state.agree.fourthStep.phoneNumber.value
                    && state.agree.fourthStep.who_delivers.value
                    && state.agree.fourthStep.contact_channel.value;
      } else if (state.agree.thirdStep.agreement_type === AGREEMENT_TYPES.COMMERCE) {
        complete = !objEmpty(state.agree.fourthStep.user.value)
                    && !objEmpty(state.agree.fourthStep.prefix.value)
                    && state.agree.fourthStep.phoneNumber.value
                    && state.agree.fourthStep.contact_channel.value;
      } else if (state.agree.thirdStep.agreement_type === AGREEMENT_TYPES.CORREO || state.agree.thirdStep.agreement_type === AGREEMENT_TYPES.TERMINAL) {
        complete = !objEmpty(state.agree.fourthStep.branch.value)
                    && !objEmpty(state.agree.fourthStep.prefix.value)
                    && state.agree.fourthStep.phoneNumber.value
                    && state.agree.fourthStep.contact_channel.value;
      }
      return complete;
    },
    completeGeocoded(state) {
      return !empty(state.agree.geocoded.latitude)
      && !empty(state.agree.geocoded.longitude)
      && !empty(state.agree.geocoded.address);
      // && !empty(state.agree.geocoded.postalCode.value)
      // && !empty(state.agree.geocoded.locality.value);
    },
  },
  actions: {
    setFirstStep({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit('SET_FIRST_STEP', payload);
        localStorage.setItem('agreeFirstStep', JSON.stringify(payload));
        resolve(state.agree.firstStep);
      });
    },
    setSecondStep({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit('SET_SECOND_STEP', payload);
        localStorage.setItem('agreeSecondStep', JSON.stringify(payload));
        resolve(state.agree.secondStep);
      });
    },
    setThirdStep({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit('SET_THIRD_STEP', payload);
        localStorage.setItem('agreeThirdStep', JSON.stringify(payload));
        resolve(state.agree.thirdStep);
      });
    },
    setFourthStep({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit('SET_FOURTH_STEP', payload);
        localStorage.setItem('agreeFourthStep', JSON.stringify(payload));
        resolve(state.agree.fourthStep);
      });
    },
    setGecoded({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit('SET_GEOCODED', payload);
        localStorage.setItem('agreeGeocoded', JSON.stringify(payload));
        resolve(state.agree.geocoded);
      });
    },
    setPropAgree({ state }, payload) {
      return new Promise((resolve) => {
        if (payload.index) {
          state.agree[payload.key][payload.index] = payload.value;
          resolve(state.agree[payload.key][payload.index]);
        } else {
          state.agree[payload.key] = payload.value;
          resolve(state.agree[payload.key]);
        }
      });
    },
    setCustomer({ state }, payload) {
      return new Promise((resolve) => {
        state.customer = payload;
        localStorage.setItem('agreeCustomer', JSON.stringify(payload));
        resolve(state.customer);
      });
    },
    setAgreement({ state }, payload) {
      return new Promise((resolve) => {
        state.agreement = payload;
        localStorage.setItem('agreement', JSON.stringify(payload));
        resolve(state.agreement);
      });
    },
    removeAgree({ state }) {
      return new Promise((resolve) => {
        Object.keys(state.agree).forEach((key) => {
          if (key === 'firstStep') {
            Object.keys(state.agree[key]).forEach((k) => {
              if (typeof state.agree[key][k].value === 'string') {
                state.agree[key][k].value = '';
              } else if (typeof state.agree[key][k].value === 'object') {
                state.agree[key][k].value = {};
              } else {
                state.agree[key][k].value = null;
              }
            });
          } else if (key === 'secondStep') {
            state.agree.secondStep.equipments = [];
          } else if (key === 'thirdStep') {
            state.agree.thirdStep.agreement_type = null;
            state.agree.thirdStep.pm = false;
            state.agree.thirdStep.all = false;
          } else if (key === 'fourthStep') {
            Object.keys(state.agree[key]).forEach((k) => {
              if (typeof state.agree[key][k].value === 'string') {
                state.agree[key][k].value = '';
              } else if (typeof state.agree[key][k].value === 'object') {
                state.agree[key][k].value = {};
              } else {
                state.agree[key][k].value = null;
              }
            });
          } else if (key === 'geocoded') {
            Object.keys(state.agree[key]).forEach((k) => {
              state.agree[key][k] = '';
            });
          } else if (key === 'options') {
            state.agree.options = {};
          }
        });
        localStorage.removeItem('agreeFirstStep');
        localStorage.removeItem('agreeGeocoded');
        localStorage.removeItem('agreeSecondStep');
        localStorage.removeItem('agreeThirdStep');
        localStorage.removeItem('agreeFourthStep');
        localStorage.removeItem('agreeCustomer');
        localStorage.removeItem('agreement');
        state.customer = null;
        state.agreement = null;
        resolve(true);
      });
    },
    async tryCompleteStoreFirstStepFromLs({ state, commit }) {
      const step = await hasLocalStorage({ key: 'agreeFirstStep' });
      if (step) {
        commit('SET_FIRST_STEP', step);
      }
      return state.agree.firstStep;
    },
    async tryCompleteStoreGeocodedFromLs({ state, dispatch }) {
      const geocoded = await hasLocalStorage({ key: 'agreeGeocoded' });
      if (geocoded) {
        await dispatch('setGecoded', geocoded);
      }
      return state.agree.geocoded;
    },
    async tryCompleteStoreSecondStepFromLs({ state, commit }) {
      const step = await hasLocalStorage({ key: 'agreeSecondStep' });
      if (step) {
        commit('SET_SECOND_STEP', step);
      }
      return state.agree.secondStep;
    },
    async tryCompleteStoreThirdStepFromLs({ state, commit }) {
      const step = await hasLocalStorage({ key: 'agreeThirdStep' });
      if (step) {
        commit('SET_THIRD_STEP', step);
      }
      return state.agree.thirdStep;
    },
    async tryCompleteStoreFourthStepFromLs({ state, commit }) {
      const step = await hasLocalStorage({ key: 'agreeFourthStep' });
      if (step) {
        commit('SET_FOURTH_STEP', step);
      }
      return state.agree.fourthStep;
    },
    async tryCompleteStoreCustomerFromLs({ state, dispatch }) {
      const step = await hasLocalStorage({ key: 'agreeCustomer' });
      if (step) {
        await dispatch('setCustomer', step);
      }
      return state.customer;
    },
    async tryCompleteStoreAgreementFromLs({ state, dispatch }) {
      const step = await hasLocalStorage({ key: 'agreement' });
      if (step) {
        await dispatch('setAgreement', step);
      }
      return state.agreement;
    },
    async completeAgreement({ state, dispatch }, payload) {
      const firstStep = {
        country: {
          value: payload.country_id,
        },
        province: {
          value: payload.province_id,
        },
        locality: {
          value: payload.locality_id,
        },
        street: {
          value: payload.street,
        },
        between_streets: {
          value: payload.between_streets,
        },
        observations: {
          value: payload.observations,
        },
        lat: {
          value: payload.lat,
        },
        lng: {
          value: payload.lng,
        },
        postalCode: {
          value: payload.postalCode,
        },
        identificacion: {
          value: payload.identificacion,
        },
        original_address: {
          value: payload.original_address,
        },
      };
      await dispatch('setFirstStep', firstStep);
      const secondStep = {
        equipments: payload.equipments,
      };
      await dispatch('setSecondStep', secondStep);
      const geocoded = {
        latitude: payload.lat,
        longitude: payload.lng,
        address: payload.street,
        postalCode: payload.postalCode,
        locality: payload.locality.name,
      };
      await dispatch('setGecoded', geocoded);
      const { agreement_type_id, option_type } = payload;
      let pm = false;
      let all = false;
      if (agreement_type_id === AGREEMENT_TYPES.VISIT) {
        if (option_type === OPTION_TYPES.PM) {
          pm = true;
        } else if (option_type === OPTION_TYPES.ALL) {
          all = true;
        }
      }
      const third = {
        agreement_type: agreement_type_id,
        pm,
        all,
      };
      await dispatch('setThirdStep', third);
      const fourthStep = {
        branch: {
          value: payload.branch,
        },
        user: {
          value: payload.user,
        },
        day: {
          value: payload.day,
        },
        schedule: {
          value: payload.schedule_id,
        },
        prefix: {
          value: payload.prefix,
        },
        phoneNumber: {
          value: payload.phoneNumber,
        },
        email: {
          value: payload.email,
        },
        who_delivers: {
          value: payload.who_delivers,
        },
        contact_channel: {
          value: payload.contact_channel_id,
        },
      };
      await dispatch('setFourthStep', fourthStep);
      return state.agreement;
    },

  },

};
