'strict mode';

const abilities = async (to, from, router, store) => {
  const token = localStorage.getItem('token');
  let secondRedirectUrl = encodeURIComponent(`${process.env.VUE_APP_ADMIN_CONTROLADOR}?token=&redirectTo=${to.fullPath}`);
  let url = `${process.env.VUE_APP_OPERACIONES_LOGIN}?redirectTo=${secondRedirectUrl}`;
  if (!token) {
    window.location.href = url;
  } else {
    const verify = await store.dispatch('auth/routeAbilities', { to, from, router });
    if (!verify) {
      window.location.href = url;
    }
  }
};

export { abilities };
