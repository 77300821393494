<template>
  <div>
      <NavbarExpress
      v-if="navbar.visible"
      :secondBlock="navbar.secondBlock"
      :thirdBlock="navbar.thirdBlock"
      @clickNavbarThirdBlock="$emit('clickNavbarThirdBlock')"
       >
          <template  v-slot:secondContent>
              <form @submit.prevent="getFilterData()" class="flex flex-row items-center">
                  <input v-model="search" class="max-w-full shadow rounded-lg p-1 px-2 resetInput" placeholder="Buscar">
                  <div v-if="loading && search" class="ml-2">
                     <LoaderSpinner height="20" spinnerColor="text-gray-400"/>
                  </div>
                   <div v-else @click="getFilterData()"  class="ml-2 flex justify-center items-center cursor-pointer" style="height:35px;width:35px;position:relative;z-index:10;">
                    <font-awesome-icon  class=" text-gray-400  " icon="search" />
                  </div>
              </form>
          </template>
      </NavbarExpress>
      <div v-if="subContent.visible" class="sub-bar-card-express">
          <div class="flex flex-row p-1 justify-between w-full">
            <div v-if="subContent.counter" class="text-gray-400 ml-1">
            {{!externalCount ? count : externalCount}} {{labelCount}}
            </div>
            <div class="flex flex-row">
              <template  v-for="(data) in subContentSlots">
                <slot :name="data" ></slot>
              </template>
              <slot name="subContentFilter">
              <div class="separator-inline-gray"></div>
              <!-- filtro -->
              <Menu
              :overlay="true"
              @exit="filterDisplay = false"
              :menu="filterDisplay"
              :cache="true"
              >
                <template v-slot:activator>
                  <div @click="openFilter()" class="text-gray-400 ml-1 cursor-pointer">
                    <span :class="['mx-2', !objEmpty(localFilters) || !objEmpty(buildFilter) ?  'text-blue-400' : '']">Filtrar</span>
                    <font-awesome-icon class="font-bold mr-1"  :icon="`angle-${filterDisplay ? 'up' : 'down'}`" />
                  </div>
                </template>
                <template v-slot:menuContent>
                    <div  class="flex justify-center items-center spinner">
                      <LoaderSpinner v-if="loading" spinnerColor="text-gray-300"/>
                  </div>
                  <div class="bg-white rounded-lg main-filter">
                    <div class="flex justify-between my-2 mx-4">
                      <div class="font-bold"> Filtrar por:</div>
                      <div><font-awesome-icon @click="filterDisplay = false" class="cursor-pointer" icon="times" /></div>
                    </div>
                    <hr class="mt-3 mb-4">
                    <FormExpress formClass="m-2" ref="refFormFilter"  @getValues="$emit('getValuesFilter',$event)" :inputs="formFilter"/>
                    <div class="flex flex-row flex-wrap my-2 mx-3 justify-between">
                      <div  class="w-28">
                        <BaseButton @click="prepareFilter({},false,true)" :disabled="loading || loading" :text="$t('Aplicar')" />
                      </div>
                      <div class="w-28">
                        <BaseButton @click="removeFilter()" :disabled="loading || loading" :text="$t('Remover')"/>
                      </div>
                    </div>
                  </div>
                </template>
              </Menu>
              </slot>
            </div>
          </div>
      </div>
      <!-- este es el contenido principal de la seccion a envolver -->
      <slot name="contentSection"></slot>
      <div v-if="(countVisible && count > 0 && response.length > 0) || (externalCount && externalCount > 0)" class="mx-2">
        <span class="text-gray-400"> {{!externalCount ? count : externalCount}} {{labelCount}}</span>
      </div>
      <BaseCard
      v-if="response.length > 0 && !objEmpty(cardSource)"
      :response="response"
      :cardSource="cardSource"
      :loading="loading"
      @clickCard="$emit('clickCard', $event)"
      @clickCardLastBlock="$emit('clickCardLastBlock', $event)"
      @lowerLimit="lowerLimit()"
      >
        <template  v-for="(data) in cardSlots" v-slot:[data.slot]="{item,index}">
          <slot :name="data.slot" v-bind="{item,index}"></slot>
        </template>
        <template v-slot:lastBlock="{item,i: index}">
          <slot name="lastBlock" v-bind="{item,i: index}"></slot>
        </template>
      </BaseCard>
      <div v-if="loading && response.length === 0 && !search" class="flex justify-center my-2">
        <svg  class="animate-spin w-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
  </div>
</template>

<script>
import NavbarExpress from '@/components/Core/Navbar/NavbarExpress.vue';
import { addParamsToRoute } from '@/services/Utils/Router';
import { objEmpty, removeDuplicates } from '@/services/Utils/Objects';
import Menu from '@/components/Core/Bar/Menu.vue';
import LoaderSpinner from '@/components/General/LoaderSpinner.vue';

export default {
  components: {
    NavbarExpress, Menu, LoaderSpinner,
  },
  props: {
    repository: {
      type: Function,
    },
    model: {
      type: String,
      default: 'data',
    },
    response: {
      type: [Object, Array],
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    cardSource: {
      type: [Array, Object],
    },
    fetchBooted: {
      // indica si al montar el componente hay que inicializar la busqueda
      type: Boolean,
      default: true,
    },
    cacheParams: {
      // indica si al realizar busqueda con filtros, debe guardar los parametros en la url para al recargar o redireccionar, tenerlos activos o guardados en la url
      type: Boolean,
      default: true,
    },
    keySearch: {
      // esta es la key del buscador para enviar al backend
      type: String,
      default: 'search',
    },
    uniqueIndex: {
      // el index para pasarle a la funcion para removes los duplicados
      type: String,
      default: 'id',
    },
    removeDuplicates: {
      // para remover duplicados en cada response comparando con lo que tengo
      type: Boolean,
      default: false,
    },
    timeWaitRequest: {
      // para remover duplicados en cada response comparando con lo que tengo
      type: Number,
      default: 300,
    },
    templateRequest: {
      // para remover duplicados en cada response comparando con lo que tengo
      type: Boolean,
      default: false,
    },
    templateKey: {
      type: String,
      default: 'template',
    },
    subContent: {
      type: Object,
      default() {
        return { visible: false };
      },
    },
    countVisible: {
      type: Boolean,
      default: false,
    },
    navbar: {
      type: Object,
      default() {
        return { visible: false };
      },
    },
    externalCount: {
      // se el puede pasar un contador externo
      type: Number,
      default: 0,
    },
    labelCount: {
      // nombre del contexto a contar
      type: String,
      default: 'resultados',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    formFilter: {
      type: Object,
    },

  },
  created() {
    this.handleRequest();
  },
  computed: {
    cardSlots() {
      return this.cardSource.fields.filter((val) => Object.prototype.hasOwnProperty.call(val, 'slot'));
    },
    subContentSlots() {
      return this.subContent.slots ?? [];
    },
  },
  data() {
    return {
      stop: false,
      loading: false,
      search: null,
      timer: 0,
      buildFilter: {},
      pagination: {},
      count: 0,
      params: {},
      filterDisplay: false,
      localFilters: {},
    };
  },
  methods: {
    async handleRequest() {
      const { query } = this.$route;
      if (this.fetchBooted) {
        if (!objEmpty(this.filters)) {
          await this.applyFilter({}, false);
        } else {
          const queryLength = Object.keys(query).length;
          // si hay parametros en query, aplico filtro
          if (queryLength > 0) {
            await this.applyFilter(query, true);
          } else {
            await this.getData();
          }
        }
      } else {
        const queryLength = Object.keys(query).length;
        // si hay parametros en query, aplico filtro
        if (queryLength > 0) {
          await this.applyFilter(query, true);
        }
      }
    },
    async getData() {
      let resultResponse = {};
      if (!this.loading) {
        if (!objEmpty(this.pagination) && !this.pagination.has_next_page) {
          return resultResponse;
        }
        // el stop sirve para cuando la peticion no devuelva nada, indique que ya no debe ejecutar nuevamente la llamada
        this.loading = true;
        this.params = {};
        // copio la metadata de la peticion para no mutar
        const pagination = { ...this.pagination };
        // calculo la pagina siguiente con la pagina actual de la paginationda
        const nextPage = Object.keys(pagination).length > 0 ? { page: pagination?.current_page + 1 } : false;

        if (this.search) this.params = { ...this.params, ...{ [this.keySearch]: this.search } };

        if (this.buildFilter) this.params = { ...this.params, ...this.buildFilter };
        if (nextPage) this.params = { ...this.params, ...nextPage };

        const result = await this.repository(this.params);
        if (result[this.model]?.length > 0) {
          const data = result[this.model];
          const response = [...this.response, ...data];
          let cleanResponse;
          if (this.removeDuplicates) {
            cleanResponse = await removeDuplicates(response, this.uniqueIndex);
          } else {
            cleanResponse = response;
          }

          if (this.returnObject) {
            this.$emit('setResponse', result);
            resultResponse = result;
          } else {
            this.$emit('setResponse', cleanResponse);
            resultResponse = cleanResponse;
          }
          if (this.cacheParams) {
            addParamsToRoute(this.params, this.$route.path);
          }

          if (result.meta) {
            this.pagination = result.meta;
            this.count = result.meta.total;
          } else if (result.pagination) {
            this.pagination = result.pagination;
            this.count = this.pagination.count;
          } else if (result.current_page) {
            this.pagination.current_page = result.current_page;
            this.pagination.last_page = result.last_page;
            this.pagination.count = result.total ? result.total : result.count;
            this.count = this.pagination.count;
          }
          this.loading = false;
          return resultResponse;
        }
        if (this.returnObject) {
          this.$emit('setResponse', result);
          resultResponse = result;
          this.loading = false;
          return resultResponse;
        }
        this.$emit('setResponse', []);
        this.loading = false;
      }
      return resultResponse;
    },
    async getFilterData(withoutDelayResponse = false) {
      return new Promise((resolve) => {
        // el stop sirve para cuando la peticion no devuelva nada, indique que ya no debe ejecutar nuevamente la llamada
        if (!this.loading) {
        // el timer es para reducir el rebote de las peticiones repetidas, solo eso...
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          this.timer = setTimeout(async () => {
            this.loading = true;
            this.params = {};
            if (this.search) { // si el BaseInput tiene algo escrito
            // Reviso si el buildFilter ya contiene la propiedad search para eliminarla y setearsela nuevamente
              if (Object.prototype.hasOwnProperty.call(this.buildFilter, this.keySearch)) {
                delete this.buildFilter[this.keySearch];
              }

              this.params = { ...{ [this.keySearch]: this.search } };
            }
            // Si buildFilter viene con filtros, seteo this.params, que es la variable encargada de contener los filtros unicamente en este metodo getFilterData()
            if (Object.keys(this.buildFilter).length > 0) {
              this.params = { ...this.params, ...this.buildFilter };
            }
            // siempre seteo la pagina con valor 1 al aplicar un filtro por primera vez. Porque en las proximas veces, el metodo getData() es el que maneja las request
            this.params = { ...this.params };
            const result = await this.repository(this.params);
            // Si no esta declarado el modelo del hash, tomo como modelo el method del repository
            if (result[this.model]?.length > 0) {
              if (this.templateRequest) {
                if (result[this.templateKey]) {
                  this.$emit('setTemplate', result[this.templateKey]);
                }
              }
              this.$emit('notFound', false);
              const data = result[this.model];
              this.clearResponse();
              const response = [...data];
              let cleanResponse;
              if (this.removeDuplicates) {
                cleanResponse = await removeDuplicates(response, this.uniqueIndex);
              } else {
                cleanResponse = response;
              }
              if (this.returnObject) {
                this.$emit('setResponse', result);
                resolve(result);
              } else {
                this.$emit('setResponse', cleanResponse);
                resolve(cleanResponse);
              }

              if (result.meta) {
                this.pagination = result.meta;
                this.count = result.meta.total;
              } else if (result.pagination) {
                this.pagination = result.pagination;
                this.count = this.pagination.count;
              } else if (result.current_page) {
                this.pagination.current_page = result.current_page;
                this.pagination.last_page = result.last_page;
                this.pagination.count = result.total ? result.total : result.count;
                this.count = this.pagination.count;
              }
              // este stop es para que no vuelva a entrar en el ciclo nuevamente porque la peticion no arroja resultados
              this.stop = false;
            } else {
              this.stop = true;

              if (this.search || !objEmpty(this.buildFilter)) {
                this.$emit('notFound', true);
              }

              if (this.returnObject) {
                this.$emit('setResponse', result);
                resolve(result);
              } else {
                this.clearResponse();
                resolve(result);
              }
            }

            this.loading = false;
            if (this.cacheParams) {
              addParamsToRoute(this.params, this.$route.path);
            }
          }, withoutDelayResponse ? 0 : this.timeWaitRequest);
        }
      });
    },
    async applyFilter(routeParams = {}, ready = false, withoutDelayResponse = false) {
      let result = {};
      if (!ready) { // ready significa que es la primera vez que renderiza la tabla sin filtros, si tiene filtros, no entra aqui.
        if (!objEmpty(this.filters) || !objEmpty(this.localFilters)) {
          this.localFilters = { ...this.localFilters, ...JSON.parse(JSON.stringify(this.filters)) };
          Object.keys(this.localFilters).forEach((k) => {
            // adentro de filter tengo el objeto con clave y valor
            if (this.localFilters[k]) {
              if (this.localFilters[k].key) {
                if (this.localFilters[k].value[this.localFilters[k].key] || this.localFilters[k].value[this.localFilters[k].key] === 0) {
                  this.buildFilter[k] = this.localFilters[k].value[this.localFilters[k].key];
                } else if (Object.prototype.hasOwnProperty.call(this.buildFilter, k)) {
                  // Si es undefined o no està la clave, verifico si esta  => clave,valor esta en los params para eliminarlo
                  delete this.buildFilter[k];
                }
              } else if (this.localFilters[k].value || this.localFilters[k].value === 0) {
                this.buildFilter[k] = this.localFilters[k].value;
              } else if (this.localFilters[k] && !Object.prototype.hasOwnProperty.call(this.localFilters[k], 'value')) {
                // aca entra si el valor no esta en value, key: { value: 'valor'}
                this.buildFilter[k] = this.localFilters[k];
              } else if (Object.prototype.hasOwnProperty.call(this.buildFilter, k)) {
                // Si es undefined o no està la clave, verifico si esta  => clave,valor esta en los params para eliminarlo
                delete this.buildFilter[k];
              }
            }
          });
        }
      } else {
        // aca entro desde el created(), porque hay filtros para aplicar
        const copyRouteParams = JSON.parse(JSON.stringify(routeParams));
        this.buildFilter = copyRouteParams; // buildFilter es el que maneja todos los filtros, TODOS!!
        this.completeFilters(this.buildFilter);
        // si el filtro construido contiene el filtro buscado de texto, se completa
        if (Object.prototype.hasOwnProperty.call(this.buildFilter, this.keySearch)) {
          this.search = this.buildFilter[this.keySearch];
        }

        // si en los filtros esta page y es el unico, no tengo porque abrir el filtro
        if (Object.prototype.hasOwnProperty.call(this.buildFilter, 'page')) {
          delete this.buildFilter.page;
        }
      }
      if (Object.keys(this.buildFilter).length === 0) {
        // si no hay filtros, limpio la url
        if (this.cacheParams) {
          await addParamsToRoute({}, this.$route.path);
        }
        result = await this.getFilterData(withoutDelayResponse);
      } else {
        result = await this.getFilterData(withoutDelayResponse);
      }
      return result;
    },
    async prepareFilter() {
      this.localFilters = await this.$refs.refFormFilter.getValues();
      await this.applyFilter({}, false, true);
    },
    openFilter() {
      this.filterDisplay = !this.filterDisplay;
    },
    completeFilters() {
      this.$emit('completeFilters', this.buildFilter);
    },
    async removeFilter() {
      await this.$refs.refFormFilter.reset();
      this.localFilters = {};
      this.buildFilter = {};
      await this.getFilterData();
    },
    clearResponse() {
      this.$emit('setResponse', []);
    },
    writing(val) {
      this.search = val;
      if (val) {
        this.getFilterData();
      } else {
        // seteo el loading falso por si acaso cuando el search este vacio e intente traer los datos
        // puede traerlos sin poblemas por el flag laoding
        this.loading = false;
        this.$nextTick(() => {
          this.getFilterData();
        });
      }
    },
    async lowerLimit() {
      await this.getData();
    },
    setProp(payload) {
      return new Promise((resolve) => {
        if (payload.index) {
          if (payload.prop) {
            this[payload.key][payload.index][payload.prop] = payload.value;
            resolve(this[payload.key][payload.index][payload.prop]);
          } else {
            this[payload.key][payload.index] = payload.value;
            resolve(this[payload.key][payload.index]);
          }
        } else {
          this[payload.key] = payload.value;
          resolve(this[payload.key]);
        }
      });
    },
    subtractCounter(count = null) {
      if (count) {
        this.count = count;
      } else {
        this.count -= 1;
      }
    },
    addCounter(count = null) {
      if (count) {
        this.count = count;
      } else {
        this.count += 1;
      }
    },

  },
  watch: {
    search(val) {
      this.writing(val);
    },
  },
  setup() {
    return { objEmpty };
  },
};
</script>

<style>
.sub-bar-card-express{
    display: flex;
    width: 100%;
    background: white;
    border-bottom: 1px solid var(--gray-super-light);
}

.spinner{
  position: fixed;
  z-index: 4;
  top: 50%;
  bottom: 50%;
  width:280px;
}

.main-filter{
  max-width:280px;
  min-width:280px;
  max-height:300px;
  overflow-y:auto;
}

@media (min-height:420px){
  .main-filter{
    max-height:400px;
  }
}

@media (min-height:580px){
  .main-filter{
    max-height:450px;
  }
}
@media (min-height:720px){
  .main-filter{
    max-height:590px;
  }
}

</style>
