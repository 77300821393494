import { hasLocalStorage } from '@/services/Utils/Objects';

export default {
  namespaced: true,
  state: {
    transaction: {
      identificacion: null,
      order: null,
      hash: null,
    },
    equipments: [],
    equipmentInputsTemplate: {},
    cacheEquipmentInputsTemplate: {},
    currentEquipment: {},
    states: [],
    equipmentAvailables: [],
    originalEquipments: [],
    enterprise: {},
    agreement: null,
  },
  getters: {
    hasTransaction(state) {
      if (!state.transaction.order || !state.transaction.identificacion || !state.transaction.hash) {
        return false;
      }
      return true;
    },
    hasOrGetTransaction: async (state) => {
      if (!state.transaction.order || !state.transaction.identificacion || !state.transaction.hash) {
        const transactionLs = await hasLocalStorage({ key: 'transaction' });
        if (transactionLs) {
          return transactionLs;
        }
        return false;
      }
      return state.transaction;
    },
  },
  actions: {
    removeTransaction({ state }) {
      localStorage.removeItem('transaction');
      localStorage.removeItem('currentEquipment');
      localStorage.removeItem('originalEquipments');
      localStorage.removeItem('templates');
      localStorage.removeItem('enterprise');
      localStorage.removeItem('equipments');
      localStorage.removeItem('agreement');
      state.transaction.identificacion = null;
      state.transaction.order = null;
      state.transaction.hash = null;
      state.equipments = [];
      state.agreement = null;
      state.equipmentInputsTemplate = {};
      state.cacheEquipmentInputsTemplate = {};
      state.currentEquipment = {};
      state.equipmentAvailables = [];
      state.enterprise = {};
      state.originalEquipments = [];
    },
    setPropEquipment({ state }, payload) {
      return new Promise((resolve) => {
        if (payload.index) {
          if (payload.prop) {
            state[payload.key][payload.index][payload.prop] = payload.value;
            resolve(state[payload.key][payload.index][payload.prop]);
          } else {
            state[payload.key][payload.index] = payload.value;
            resolve(state[payload.key][payload.index]);
          }
        } else {
          state[payload.key] = payload.value;
          resolve(state[payload.key]);
        }
      });
    },
    async hiddenFieldsToNegative({ state }, payload) {
      Object.keys(state.equipmentInputsTemplate).forEach((k) => {
        if (k !== 'equipment_state_id') {
          state.equipmentInputsTemplate[k].hidden = payload;
        }
      });
    },
  },

};
