const objEmpty = (object) => {
  let result = true;
  if (!object) {
    result = true;
  } else if (object && Object.keys(object).length > 0) {
    result = false;
  }
  return result;
};

const objEquals = (first, last) => {
  if (Object.entries(first).toString() !== Object.entries(last).toString()) {
    return false;
  }
  return true;
};

const empty = (str) => {
  if (!str || str === '') {
    return true;
  }
  return false;
};

const removeDuplicates = (arr, key) => new Promise((resolve) => {
  const remove = arr.filter((val, index, array) => array.findIndex((find) => find[key] === val[key]) === index);
  resolve(remove);
});

const hasLocalStorage = (payload) => new Promise((resolve) => {
  let result = false;
  let ls = localStorage.getItem(payload.key);

  if (ls) {
    ls = JSON.parse(ls);
    if (payload.index) {
      if (payload.prop) {
        if (ls[payload.key][payload.index][payload.prop]) {
          resolve(ls[payload.key][payload.index][payload.prop]);
        } else {
          resolve(false);
        }
      } else if (ls[payload.key][payload.index]) {
        result = ls[payload.key][payload.index];
        resolve(result);
      } else {
        resolve(false);
      }
    } else {
      resolve(ls);
    }
  } else {
    resolve(false);
  }
});

export {
  objEmpty, objEquals, removeDuplicates, empty, hasLocalStorage,
};
