import { createRouter, createWebHistory } from 'vue-router';
import guard from '@/router/guard';
import HomeRoute from '@/router/Home';
import AuthenticationRoute from '@/router/Authentication';
import UsersRoute from '@/router/usersRoute';
import DashboardRoute from '@/router/dashboardRoute';
import EquipmentManagementRoute from '@/router/equipmentManagementRoute';
import SettingsRoute from '@/router/settingsRoute';
import CoverageRoute from '@/router/coverageRoute';
import AgreementRoute from '@/router/agreementRoute';
import SyncRoute from '@/router/syncRoute';

import store from '@/store';

const routes = [
  ...UsersRoute,
  ...DashboardRoute,
  ...EquipmentManagementRoute,
  ...AuthenticationRoute,
  ...CoverageRoute,
  ...AgreementRoute,
  ...SyncRoute,
  ...SettingsRoute,
  ...HomeRoute,
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/Helpers/NotFound.vue'),
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/Helpers/401.vue'),
  },
  {
    path: '',
    redirect: '/dashboard',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  await guard(to, from, router, store);
  next();
});
export default router;
