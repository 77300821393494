/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import $http from '@/services/Api/AxiosInstance';
import { objEmpty } from '@/services/Utils/Objects';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    user: {},
    loading: false,
    loadingAuth: false,
    loadingUser: false,
    booted: true,
    abilities: [],
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
      localStorage.setItem('token', payload);
    },
    REMOVE_TOKEN(state) {
      state.token = '';
      localStorage.removeItem('token');
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ABILITIES(state, payload) {
      state.abilities = [...payload];
    },
    REMOVE_ABILITIES(state) {
      state.abilities = [];
    },
    CLEAN_STORE(state) {
      state.user = {};
      state.abilities = [];
      state.token = '';
      // localStorage.removeItem('token');
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit('SET_TOKEN', payload);
    },
    removeToken({ commit }, payload) {
      commit('REMOVE_TOKEN', payload);
    },
    removeAbilities({ commit }) {
      commit('REMOVE_ABILITIES');
    },
    setUser({ commit }, payload) {
      commit('SET_USER', payload);
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
    async routeAbilities({ commit, state }, payload) {
      const { to, router } = payload;
      const token = localStorage.getItem('token');
      if (!token) {
        router.push({ name: 'Logout' });
      }

      let verify = false;
      if (state.abilities.length === 0) {
        state.loadingAuth = true;
        await $http.get('v1/authentication/abilities')
          .then((res) => {
            const result = res;
            const { abilities: availableabilities } = result.data;
            commit('SET_ABILITIES', availableabilities);
            const hasAbilities = availableabilities.some((available) => available.nombre === to.meta.gate);
            verify = hasAbilities;
            state.loadingAuth = false;
          })
          .catch(() => {
            state.loadingAuth = false;
          });
      } else {
        const hasAbilities = state.abilities.some((available) => available.nombre === to.meta.gate);
        verify = hasAbilities;
      }

      return verify;
    },
    async checkToken({ commit, state }) {
      let result = {};
      if (!state.token) {
        result = false;
        return result;
      }
      if (objEmpty(state.user)) {
        try {
          const response = await $http.get('v1/authentication/checkToken');
          commit('SET_USER', response.data.user);
          result = state.user;
        } catch (error) {
          result = false;
        }
      } else {
        result = state.user;
      }
      return result;
    },
    checkPermission({ state }, payload) {
      return state.abilities.some((value) => value.nombre === payload);
    },
    cleanStore({ commit }) {
      commit('CLEAN_STORE');
    },
    setCoordinates({ state }, payload) {
      state.user.lat = payload.lat;
      state.user.lng = payload.lng;
    },
    setPropAuth({ state }, payload) {
      return new Promise((resolve) => {
        if (payload.index) {
          if (payload.prop) {
            state[payload.key][payload.index][payload.prop] = payload.value;
            resolve(state[payload.key][payload.index][payload.prop]);
          } else {
            state[payload.key][payload.index] = payload.value;
            resolve(state[payload.key][payload.index]);
          }
        } else {
          state[payload.key] = payload.value;
          resolve(state[payload.key]);
        }
      });
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    checkPermission: (state) => (permission) => state.abilities.some((value) => value.nombre === permission),
  },
};
