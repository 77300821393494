// eslint-disable-next-line valid-typeof
export const isTypeValue = (value, type) => typeof value === type;

export const isEmpty = (value) => { // Return true if condition succed
  if (value === null || value === undefined) {
    return true;
  }
  switch (typeof value) {
    case 'string':
      return !value || value.length === 0;
    case 'object':
      if (Array.isArray(value)) return value.length === 0;
      return Object.keys(value).length === 0;
    default:
      return false;
  }
};

export const isDate = (date) => Boolean(Date.parse(date));

export const minLength = (value, min) => !isEmpty(value) && value.length >= min; // Return true if condition succed
export const maxLength = (value, max) => !isEmpty(value) && value.length <= max;

export const isEmail = (value) =>
  // eslint-disable-next-line no-useless-escape, implicit-arrow-linebreak
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    value,
  );

export const contain = (originalValue, values) => originalValue && values.includes(originalValue);
