<script>
import { h } from 'vue';
import debounce from '@/services/Utils/Debounce';
import getProperty from '@/services/Utils/Property';

export default {
  emits: ['lowerLimit'],
  props: {
    response: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hasResponse() {
      if (this.response?.length > 0) {
        return true;
      }
      return false;
    },
    quantity() {
      return this.response.length;
    },
  },
  methods: {
    scrollTable() {
      const tableWrapper = document.querySelector('.table-wrapper');
      // desplazamiento superior
      const scrollTopTableWrapper = tableWrapper.scrollTop;
      // altura de compensación para la envoltura de tabla
      const offsetHeightTableWrapper = tableWrapper.offsetHeight;

      const tableMain = document.querySelector('.table-main');
      // altura de compensación para la tabla
      const offsetHeightTableMain = tableMain.offsetHeight;

      // si el desplazamiento superior de la envoltura mas la altura de compensacion de envoltura es igual a la altura de compensacion de la tabla
      if ((scrollTopTableWrapper + offsetHeightTableWrapper) >= offsetHeightTableMain) {
        this.$emit('lowerLimit');
      }
    },
    desktop() {
      let buildRow = [];
      let buildColumn = [];
      if (this.hasResponse) {
        const responseCopy = JSON.parse(JSON.stringify(this.response));

        // Estas child columns es porque esta anidado al componente padre tableExpres
        // Si no fuera asi, no fueran hijas y usaria this.$slots.columns() como principal
        const [childColumns] = this.$slots.columns();
        if (childColumns) {
        // ESTAS SON LAS COLUMNAS DE LA TABLA
          buildColumn = childColumns.children.map((col) => h('th', col));
          // ACA RECORRO LA DATA DEL BACKEND PARA ARMAR LOS TR DE LA TABLA, Y POR CADA TR QUE RECORRO, BUSCO LA key A RENDERIZAR, O SI ES UN SLOT
          buildRow = responseCopy.map((row, index) => h('tr', { class: '' }, childColumns.children.map((slotContent) => {
            // estructura del render
            // tag
            // atributos
            // retorno (puedes retonar un valor o anidar otro tag, sucesivamente)
            //---------------------------------------------------------------------
            // ESTE ES PARA EL SLOT
            if (slotContent.children) {
              return h('td',
                { class: 'border-table-express' },
                slotContent.children.action({ content: row, index }));
            }
            // SI LA key ES UN OBJETO
            if (slotContent.key.includes('.')) {
              return h('td',
                { class: 'border-table-express', style: slotContent.props.fullText ? 'white-space: nowrap;' : '' },
                slotContent.props.format ? slotContent.props.format(getProperty(row, slotContent.key)) : getProperty(row, slotContent.key) || '');
            }
            // key SIN OBJETO
            return h('td',
              { class: 'border-table-express', style: slotContent.props.fullText ? 'white-space: nowrap;' : '' },
              slotContent.props.format ? slotContent.props.format(row[slotContent.key]) : row[slotContent.key]);
          })));
        } else {
          console.log('DEBES Construirlas no siendo hijas this.$slots.columns()');
        }
        const loader = this.loading ? h('div', { class: 'continuous-1' }, '') : h('div', { style: 'height:4px;background:white;' }, '');
        return h('div', {}, [
          h('div', { class: 'my-1 font-bold text-gray-400' }, `Resultados ${this.count}`),
          loader,
          h('div',
            {
              class: 'table-wrapper',
              onScroll: debounce(this.scrollTable, 200),
            },

            [h('table',
              { class: 'table-main' },
              [

                h('thead', { },
                  h('tr', {}, buildColumn)),
                h('tbody', { class: 'tbody' }, buildRow),
              ])]),
        ]);
      }

      // cuando entra aqui la tabla esta vacia
      const [childColumns] = this.$slots.columns();
      if (childColumns) {
        buildColumn = childColumns.children.map((col) => h('th', col));
      } else {
        console.log('DEBES Construirlas no siendo hijas this.$slots.columns()');
      }
      return h(
        'div',
        {
          class: 'table-wrapper',
          onScroll: debounce(this.scrollTable, 200),
        },

        h('table',
          { class: 'table-main shadow-lg' },
          [
            h('thead', { },
              h('tr', {}, buildColumn)),
            h('tbody', { class: 'tbody' }, h('tr', {}, '')),
          ]),
      );
    },
  },
  render() {
    /* eslint-disable indent */
    return this.desktop();
  },

};
</script>

<style>
table thead tr th:last-child {
  text-align: start;
  /* background-color: blue; */
}

table tbody tr td:last-child {
  /* background-color: blue; */
  text-align: start;
  width: 100%;
}

/* table td:last-child {
    width: 100%;
    /* text-align: start; */
/* }  */

.table-wrapper::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background: #cfcece;
    -webkit-border-radius: 1ex;
    /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); */
}

.table-wrapper {
  max-height: 33rem;
 /* background:red; */
  overflow-y: scroll;
  overflow-x: auto;
  background: white;
}

.table-main{
  /* min-width: 100%; */
}

table {
  /* border-collapse: collapse; */
  /* box-shadow: 0 5px 10px #e1e5ee; */
  table-layout: auto;
  background: white;

}

table > thead {
  background: white;
  /* box-shadow: 0 5px 10px #e1e5ee; */
  padding:1rem 2rem;
  position: sticky;
  /* z-index: var(--z-7); */
  top: 0;

}

 thead > tr > th {
  padding: 0 16px;
  width: auto;

}

table > tbody > tr {
  margin: 5px !important;
  height: 50px;
}

table > tbody > tr > td {
  color: rgb(2, 0, 0);
  text-align: center;
  padding: 0.1rem 16px;

}

.continuous-1 {
  width: 100%;
  height:4px;
  background:
    linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%)
    #f2f2f2;
  background-size:300% 100%;
  animation: ct1 1s infinite linear;
}

@keyframes ct1 {
  0% {background-position: right}
}

.border-table-express{
  --tw-border-opacity: 1;
  border-top: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));

}

</style>
