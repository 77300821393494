<template>
  <div :class="['select-complete','relative',`select-complete-${placeHolderTitle}`]" ref="list">
    <label @click="activeFromLabel" :class="[classLabel]">{{ placeholder }}</label>
    <input
      @input="filter()"
      @focus="handleFocus()"
      @keyup="nextItem($event)"
      v-model="search"
      ref="BaseSelect"
      :class="classSelect"
      autocomplete="new-user"
      :disabled="disabled"
    />
    <template v-if="remove && hasValue">
      <div @click="clearSearch()" class="remove">
        <font-awesome-icon class="text-gray-500" icon="times" />
      </div>
    </template>
    <div :style="positionList" ref="listContainer" v-if="showList" :class="['list-container-select', `list-container-select-${placeHolderTitle}`]">
      <ul style="position: static">
        <template v-if="!noResults">
          <li
            @click.stop="setSelect(index)"
            @keyup.enter="setSelect(index)"
            :class="['list-item-select',{ 'list-item-selected' : index ===  currentItem}, `selected-${index}` ]"
            v-for="(item, index) in internalOptions"
            :key="index"
            tabindex="0"
          >
          <!-- {{$slots.itemList}} -->
              <!-- {{test($slots)}} -->
              <div v-if="$slots.itemList">
                <slot name="itemList" v-bind="{item,index}">
                </slot>
              </div>
              <div v-else :class="['item-select']">
               {{ label.indexOf('.') > -1 ? getProperty(item, label) : item[label] }}
              </div>
          </li>
        </template>
        <template v-if="noResults">
          <li class="break-all"><div :class="['item-select', `item-select-${placeHolderTitle}`]">No se encontraron resultados</div></li>
        </template>
      </ul>
    </div>
    <div
      v-if="hasError"
      class="text-red-500 font-bold text-xs mt-1 ml-3"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import { objEmpty } from '@/services/Utils/Objects';
import getProperty from '@/services/Utils/Property';
import { innerDimensions } from '@/services/Utils/Doom';

export default {
  emits: ['update:modelValue', 'content-change', 'errorHandle'],
  props: {
    placeholder: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: [Object, String, Number, Boolean],
    error: {
      type: String,
      default: '',
    },
    // Es el key del objeto a devolver;
    trackBy: {
      type: String,
      default: 'id',
    },
    // es la key que usara para buscar el string en el objeto, a veces solo tenemos el nombre de una propiedad y no el id, y para hacer match con ese nombre
    // especifico la key aqui
    trackByString: {
      type: String,
      default: '',
    },
    // label es la key que se muestra como nombre de los valores y la key que se mapea al buscar con el search
    label: {
      type: [String, Function],
      default: 'name',
    },
    keyName: {
      // este es el nombre de la propiedad que sera devuelta, ejemplo, normalmente devuelve = {id:1}, si keyName = 'postalCode', devuelve = {postalCode: 1}
      type: String,
      default: '',
    },
    fatherDepth: {
      // es 3 por defecto, porque el primer padre del list item es el div select-complete
      // luego lo envuelve un div, y luego otro div, del contenido
      type: Number,
      default() {
        return 3;
      },
    },
    outline: {
      type: String,
      default: 'base',
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // activa el remove del select
    remove: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    returnAfterMatch: {
      // despues de intentar completar el objeto pasandole un id u otra propiedad, siempre queda el valor del que le seteamos desde afuera primero para iniciar la busqueda,
      // y nunca se devuelve lo que se encontro para no repetir el llamado de hijo a padre seteando lo matcheado. A veces queremos devolver ese match, habitualmente se usa cuando se hacen match con string
      type: Boolean,
      default: false,
    },
    // aca se declara las propiedades para hacer match en el objeto
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
    // si value es true, se devuelve el valor como tal elegido
    debug: {
      // habilitado para debuguar errores
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    classSelect() {
      if (this.focused || (!this.focused && this.search !== '')) {
        if (!this.hasError) {
          return `select-complete__select  border-base border-${this.outline} active-input`;
        }
        return 'select-complete__select  border-error active-input ';
      }
      if (this.hasError) {
        return 'select-complete__select  border-error active-input';
      }
      return 'select-complete__select border-base ';
    },
    classLabel() {
      // si esta enfocado o no esta enfocado y si tiene un valor escrito ,se le coloca la clase active
      if (this.focused || (!this.focused && this.search)) {
        return `select-complete__label ${this.hasError ? 'active-label-error' : `active-label-${this.outline}`}`;
      }
      return 'select-complete__label';
    },
    showList() {
      if (this.focused && this.options?.length > 0) {
        return true;
      }
      if (this.search && this.options?.length > 0 && this.focused) {
        return true;
      }
      return false;
    },
    placeHolderTitle() {
      return this.placeholder.replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    },
    hasError() {
      return this.error && this.error !== '';
    },
    hasValue() {
      if (this.modelValue || !objEmpty(this.modelValue)) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      search: '',
      focused: false,
      internalOptions: [],
      debounce: false,
      lastFound: '',
      pendingJob: false,
      noResults: false,
      cache: [],
      currentItem: 0,
      topList: false,
      positionList: '',
      touchSelect: false, // este es un flag para saber cuando estoy manipulando el select
    };
  },

  methods: {
    filter() {
      this.touchSelect = true;
      if (this.search) {
        if (this.type === 'fetch') {
          this.$emit('update:modelValue', this.search);
          if (this.options.length > 0 || this.internalOptions.length > 0) {
            this.position();
          }
          return;
        }
        // Este metodo busca la palabra escrita en objeto que tenemos para mostrar en el select, filtro la palabra y muestro el resultado
        if (this.cache.length === 0) {
          this.cache = this.internalOptions;
        }

        let filter = [];
        if (this.filters.length > 0) {
          // si tiene filtros es porque debemos aplicar el filtro basado en las propiedades que nos pasan ejem: ['nombre','apellido'], el filtro concatenara nombre y apellido para luego hacer un match en ese string
          const withFilter = [...this.cache];
          withFilter.forEach((val, index) => {
            let property = '';
            this.filters.forEach((f) => {
              property += `${getProperty(val, f)} `;
            });
            withFilter[index].filter = property.trim();
          });
          filter = withFilter.filter((data) => data.filter.toLowerCase().indexOf(this.search.trim().toLowerCase()) > -1);
        } else {
          filter = this.cache.filter((data) => data[this.label].toString().toLowerCase().indexOf(this.search.trim().toString().toLowerCase()) > -1);
        }
        if (filter.length > 0) {
          if (!this.focused) {
            this.focused = true;
          }
          this.internalOptions = filter;
          this.noResults = false;
          if (this.hasError) {
            this.$emit('errorHandle', '');
          }
          this.$nextTick(() => {
            this.position();
            // this.calculatePositionListSelect(filter);
          });
        } else {
          this.noResults = true;
          this.position();
          // this.calculatePositionListSelect([]);
        }
      } else if (this.cache.length > 0) {
        this.internalOptions = this.cache;
        this.noResults = false;
        this.$nextTick(() => {
          this.position();
          // this.calculatePositionListSelect(this.internalOptions);
        });

        // this.calculatePositionListSelect(this.cache);
      } else if (this.type === 'fetch') {
        this.$emit('update:modelValue', this.search);
      }
      this.$nextTick(() => {
        this.touchSelect = false;
      });
    },
    handleFocus() {
      this.focused = true;
    },
    activeFromLabel() {
      if (!this.focused) {
        this.$refs.BaseSelect.focus();
      }
    },
    setSelect(index) {
      this.touchSelect = true;
      let value;

      const selected = JSON.parse(JSON.stringify(this.internalOptions)).filter((val, internalIndex) => index === internalIndex)[0];
      if (this.returnObject) {
        value = selected;
      } else if (this.keyName) {
        value = {
          [this.keyName]: selected[this.trackBy],
        };
      } else {
        value = selected[this.trackBy];
        // value = {
        //   [this.keyName]: selected[this.trackBy],
        // };
      }

      this.$emit('update:modelValue', value); // devuelvo el objeto al padre
      this.$emit('content-change', value);

      if (this.hasError) {
        this.$emit('errorHandle', '');
      }
      this.setSearch(selected);
      this.focused = false;
      this.lastFound = this.search;
      this.debounce = false;
      this.$nextTick(() => {
        this.touchSelect = false;
      });
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target) && this.focused) {
        this.focused = false;
        if (!this.search && this.noResults && this.cache.length > 0) {
          this.internalOptions = this.cache;
          this.noResults = false;
        }
      }
    },
    calculatePositionListSelect(val) {
      // let wrapper;
      // screen = window.innerHeight;
      const list = document.querySelector(`.list-container-select-${this.placeHolderTitle}`);
      if (list) {
        // if (this.fatherDepth === 3) {
        //   wrapper = list.parentNode.parentNode.parentNode.clientHeight;
        // }

        // const screen = window.innerHeight;

        if (this.showList) {
          const select = document.querySelector(`.select-complete-${this.placeHolderTitle}`);
          // const distance = wrapper - (select.offsetTop + select.offsetHeight);
          // const distanceScreen = screen - (select.offsetTop + select.offsetHeight);
          const distanceSelectwithTopScreen = select.getBoundingClientRect().top + document.documentElement.scrollTop;
          const heightList = innerDimensions(list).height;
          // const distanceListwithTopScreen = lost.getBoundingClientRect().top + document.documentElement.scrollTop;
          // select.offsetTop > 150 ES PARA VERIFICAR QUE EL MARGIN DEL PADRE EN RELACION AL TOP DEL SELECT TENGA SUFICIENTE DISTANCIA PARA PONER EL LIST HACIA ARRIBA !!!
          if ((distanceSelectwithTopScreen > 283)) {
            this.topList = true;
            if (val.length >= 5) {
              list.style.top = '-313px';
            } else if (val.length === 4) {
              if (heightList <= 224) { // esta es la altura teniendo una linea por item
                list.style.top = '-240px';
              } else if (heightList <= 250) {
                list.style.top = '-280px';
              } else {
                list.style.top = '-313px';
              }
            } else if (val.length === 3) {
              if (heightList <= 168) { // esta es la altura teniendo una linea por item
                list.style.top = '-190px';
              } else if (heightList <= 200) {
                list.style.top = '-220px';
              } else if (heightList <= 240) {
                list.style.top = '-262px';
              } else {
                list.style.top = '-313px';
              }
            } else if (val.length === 2) {
              if (heightList <= 115) { // esta es la altura teniendo una linea por item
                list.style.top = '-132px';
              } else if (heightList <= 145) {
                list.style.top = '-168px';
              } else if (heightList <= 160) {
                list.style.top = '-185px';
              } else if (heightList <= 165) {
                list.style.top = '-190px';
              } else if (heightList <= 185) {
                list.style.top = '-215px';
              } else if (heightList <= 210) {
                list.style.top = '-233px';
              } else if (heightList <= 240) {
                list.style.top = '-257px';
              } else if (heightList <= 265) {
                list.style.top = '-285px';
              } else {
                list.style.top = '-313px';
              }
            } else if (val.length === 1) {
              if (heightList <= 60) {
                list.style.top = '-80px';
              } else if (heightList <= 80) {
                list.style.top = '-102px';
              } else if (heightList <= 104) {
                list.style.top = '-125px';
              } else if (heightList <= 129) {
                list.style.top = '-149px';
              } else if (heightList <= 153) {
                list.style.top = '-175px';
              } else if (heightList <= 177) {
                list.style.top = '-199px';
              } else if (heightList <= 201) {
                list.style.top = '-220px';
              } else if (heightList <= 225) {
                list.style.top = '-244px';
              } else if (heightList <= 249) {
                list.style.top = '-270px';
              } else if (heightList <= 273) {
                list.style.top = '-293px';
              } else {
                list.style.top = '-313px';
              }
            }
          } else {
            list.style.top = '54px';
            this.topList = false;
          }
        }

        if (this.noResults) {
          const select = document.querySelector(`.select-complete-${this.placeHolderTitle}`);
          // const distance = wrapper - (select.offsetTop + select.offsetHeight);
          // const distanceScreen = screen - (select.offsetTop + select.offsetHeight);
          const distanceSelectwithTopScreen = select.getBoundingClientRect().top + document.documentElement.scrollTop;
          // if ((select.offsetTop > 283 && distanceSelectwithTopScreen > 380) && (distance < 50 || distanceScreen < 150)) {
          if ((distanceSelectwithTopScreen > 283)) {
            list.style.top = '-75px';
          } else {
            list.style.top = '54px';
          }
        }
      }
    },
    setSearch(selected) {
      if (this.label.indexOf('.') > -1 && selected) {
        this.search = getProperty(selected, this.label);
      } else if (selected || selected === 0) {
        this.search = selected[this.label];
      }
    },
    observerModelValue() {
      //   lastFound tiene que estar vacia para no repetir infita esta funcion,  y que lastFound sea a search(el ultimo valor buscando recientamente )
      //   cada vez que se dispara el watch

      if (this.modelValue) { // si es un objeto, vacio o lleno siempra entrara aqui
        // aca entra para completar objetos
        if (typeof this.modelValue === 'object' && !objEmpty(this.modelValue)) { // aca verifico que no este vacio el bojeto
          if (this.internalOptions.length > 0) { // verifico tener opciones disponibles para tener donde buscar
            const filter = this.internalOptions.filter((val) => {
              if (typeof val[this.trackBy] === 'string' && typeof this.modelValue[this.trackBy] === 'string') {
                return val[this.trackBy].toString().toLowerCase() === this.modelValue[this.trackBy].toString().toLowerCase();
              }

              return val[this.trackBy]?.toString() === this.modelValue[this.trackBy]?.toString();
            });
            if (filter.length > 0) {
              const [found] = filter;
              this.setSearch(found);
              this.focused = false; // saco el foco del input
              this.debounce = true; // activo el debounce "por si acaso" para que no repita la funcion indefinidamente
              this.noResults = false; // resteo el noresult para no mostrar error del select
            } else {
              this.search = '';
            }
            this.pendingJob = false;
          } else {
            this.pendingJob = true;
          }

          // aca entra para completar valores planos
        } else if (typeof this.modelValue === 'string' || typeof this.modelValue === 'number') {
          if (this.internalOptions.length > 0) {
            const filter = this.internalOptions.filter((data) => {
              if (typeof this.modelValue === 'string') {
                return data[this.trackByString ? this.trackByString : this.trackBy].toString().toLowerCase() === this.modelValue.toString().toLowerCase();
              }
              return data[this.trackBy] === this.modelValue;
            });
            if (filter.length > 0) {
              const [found] = filter;
              this.setSearch(found);
              if (this.returnAfterMatch) {
                let value;
                if (this.returnObject) {
                  value = found;
                } else if (this.keyName) {
                  value = {
                    [this.keyName]: found[this.trackBy],
                  };
                } else {
                  value = found[this.trackBy];
                }
                this.$emit('update:modelValue', value); // devuelvo el objeto al padre
                this.$emit('content-change', value);
                if (this.hasError) {
                  this.$emit('errorHandle', '');
                }
              }
              this.focused = false; // saco el foco del input
              this.debounce = true; // activo el debounce "por si acaso" para que no repita la funcion indefinidamente
              this.noResults = false; // resteo el noresult para no mostrar error del select
            } else {
              this.search = '';
            }

            this.pendingJob = false;
          } else {
            if (this.debug) {
              console.error('DEBUGUEANDO TRABAJO PENDIENTE', this.placeHolderTitle);
            }
            this.pendingJob = true; // tiene trabajo pendiente para cuando se vuelva a ejecutar la funcion, porque posiblemente no hay opciones disponibles
          }
        }
      }
    },
    clearSearch() {
      this.touchSelect = true;
      this.search = '';
      this.$emit('update:modelValue', null);
      if (this.focused) this.focused = false;
      this.$nextTick(() => {
        this.touchSelect = false;
        if (this.cache.length > 0) {
          this.internalOptions = this.cache;
        }
      });
    },
    openList() {
      this.focused = true;
    },
    nextItem(ev) {
      if (ev.key === 'ArrowUp' || ev.Code === 38) {
        if (this.internalOptions.length > 0 && this.currentItem > 0) {
          this.currentItem -= 1;
          const select = document.querySelector(`.selected-${this.currentItem}`);
          const listContainer = document.querySelector(`.list-container-select-${this.placeHolderTitle}`);

          const parentTop = listContainer.getBoundingClientRect().top;
          const currentChildTop = select.getBoundingClientRect().top;

          // esto es para mostrar los items escondidos arriba
          if (select) {
            if (currentChildTop - parentTop < 5) {
              if (this.currentItem > 0) {
                this.$refs.listContainer.scrollTop -= 56; // 56px es el tamaño del item en la lista
              } else {
                document.querySelector(`.list-container-select-${this.placeHolderTitle}`).scrollTop = 0;
              }
            }
          }
        }
      }

      if (ev.key === 'ArrowDown' || ev.Code === 40) {
        if (this.internalOptions.length > 0 && this.currentItem < this.internalOptions.length - 1) {
          this.currentItem += 1;
          this.$nextTick(() => {
            const select = document.querySelector(`.selected-${this.currentItem}`);
            const listContainer = document.querySelector(`.list-container-select-${this.placeHolderTitle}`);
            if (select) {
              const parentTop = listContainer.getBoundingClientRect().top;
              const currentChildTop = select.getBoundingClientRect().top;
              if (this.topList) {
                if (currentChildTop - parentTop > 253 && this.internalOptions.length > 4) {
                  this.$refs.listContainer.scrollTop += select.offsetHeight; // select.offsetHeightpx es el tamaño del item en la lista
                }
              } else if (currentChildTop - parentTop > 253 && this.internalOptions.length > 4) {
                this.$refs.listContainer.scrollTop += select.offsetHeight; // select.offsetHeightpx es el tamaño del item en la lista
              }
            }
          });
        }
      }

      if (ev.key === 'Enter' || ev.code === 'Enter') {
        if (this.internalOptions.length > 0) {
          this.setSelect(this.currentItem);
        }
      }
    },
    position() {
      const { listContainer, BaseSelect } = this.$refs;
      if (listContainer && BaseSelect) {
        const selectPosition = BaseSelect.getBoundingClientRect();
        const realListPosition = Math.ceil(selectPosition.top - listContainer.clientHeight);
        const positionDownSelect = Math.ceil((selectPosition.top + selectPosition.height));
        if (selectPosition.top > 200) {
          if (listContainer.clientHeight > selectPosition.top) {
            this.positionList = `top:${positionDownSelect}px;max-height:${selectPosition.top}px;width:${selectPosition.width}px;`;
          } else {
            this.positionList = `top:${realListPosition}px;width:${selectPosition.width}px;height:${listContainer.clientHeight > 304 ? '304px' : 'auto'};max-height:${selectPosition.top > 304 ? 304 : selectPosition.top}px;`;
          }
        } else if (selectPosition.bottom > 304) {
          this.positionList = `top:${positionDownSelect}px;max-height:304px;width:${selectPosition.width}px`;
        } else if (selectPosition.bottom < 304 && selectPosition.top < 200) {
          this.positionList = `top:${positionDownSelect}px;max-height:304px;width:${selectPosition.width}px`;
        } else {
          this.positionList = `top:${realListPosition}px;width:${selectPosition.width}px;max-height:${selectPosition.top}px;`;
        }
      }
    },
    getScrollParent(node) {
      if (node == null) {
        return null;
      }

      if (node.scrollHeight > node.clientHeight) {
        return node;
      }
      return this.getScrollParent(node.parentNode);
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('resize', this.position);
    // SI TIENE OPCIONES AL RENDERIZAR, LAS TOMO
    if (this.options?.length > 0) {
      this.internalOptions = this.options;
    }

    // SI TIENE VALOR POR DEFECTO AL RENDERIZAR
    this.observerModelValue();
  },
  watch: {
    options: {
      handler(val) {
        if (this.type !== 'fetch') {
          if (val.length === 0) {
            this.search = '';
            this.cache = [];
          } else {
            this.noResults = false;
          }
        } else {
          this.cache = [];
        }

        this.internalOptions = val;
        if (this.pendingJob) {
          this.observerModelValue();
        }
      },
      deep: true,
    },
    modelValue: {
      handler(newVal, oldVal) {
        // esto es para que cuando se vacie el select externamente, pueda retornar el objeto vacio

        if ((newVal !== oldVal)) {
          if (!newVal && !this.touchSelect) {
            this.$emit('update:modelValue', null);
            this.search = '';
          }
        }
        this.observerModelValue();
      },
      deep: true,
    },
    showList() {
      this.$nextTick(() => {
        // const resizer = new ResizeObserver(this.position);
        if (this.showList) {
          this.position();
        } else {
          this.currentItem = 0;
        }
      });
    },
  },
  setup() {
    return { getProperty };
  },
};
</script>

<style scoped>
.select-complete {
  position: relative;
  display:block;
  height: 100%;
  max-width: 100%;

}
.select-complete__select {
  outline: none;
  border-radius: 7px;
  height:52px;
  max-width: 100%;
  width:100%;
  padding:0 10px;
  font-size: 13.5px;
  font-weight: 400;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-complete__label {
  position: absolute;
  top: 17px;
  left: 16.5px;
  font-size: 12px;

  transition: all 0.3s;
  color: var(--gray);
  cursor: text;
}
.active-label-base{
  top: 4px;
  transition: all .3s;
  color: var(--gray);
}
.active-label-blue{
  top: 4px;
  transition: all .3s;
  color: var(--blue);
}
.active-label-black{
  top: 4px;
  transition: all .3s;
  color: var(--black);
}
.active-label-error{
  top: 4px;
  transition: all .3s;
  color: var(--red);
}

/* border */

.border-base {
  border: 1px solid rgb(215, 214, 218);
}
.border-blue {
border: 1px solid var(--blue);
}
.border-black {
border: 1px solid var(--black);
}
.border-error {
border: 1px solid var(--red);
}

.active-input {
  padding-top: 20px;
}
.list-container-select {
  background: rgb(255, 255, 255);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  padding: 5px 0px;
  position: fixed;
  z-index: var(--z-8);
  max-height:304px;
  contain: content;
  max-width: 400px;
  overflow: auto;
  transition: all .2s;
}

.item-select {
  padding: 16px 10px;
}
.list-item-select {
  transition: background 0.05s;
  position: relative;
}

.list-item-selected {
   background: rgba(231, 224, 224, 0.747);
  cursor: pointer;
}
.list-item-select:hover {
  background: rgba(245, 239, 239, 0.432);
  cursor: pointer;
}

.remove{
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}
</style>
