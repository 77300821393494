// USUARIOS
export const USER_STATES = {
  ACTIVO: 1,
  INACTIVO: 2,
  PENDIENTE: 3,
  BANEADO: 4,
  VALIDADO: 5,
  COMPLETADO: 6,
};

export const ALL_USER_STATES = [
  { id: 1, name: 'ACTIVO' },
  { id: 2, name: 'INACTIVO' },
  { id: 3, name: 'PENDIENTE' },
  { id: 4, name: 'BANEADO' },
  { id: 5, name: 'VALIDADO' },
  { id: 6, name: 'COMPLETADO' },
];

export const UP_DOWN_USER_STATES = [
  { id: 1, name: 'ACTIVO' },
  { id: 2, name: 'INACTIVO' },
];

// PACTADOS
export const AGREEMENT_STATES = {
  PACTADO: 1,
  CONFIRMADO: 2,
  RETIRO_PARCIAL: 3,
  RETIRO_FALLIDO: 4,
  RETIRO_TOTAL: 5,
  SIN_OPCIONES: 6,
  MAL_PACTADO: 7,
  CANCELADO_CLIENTE: 8,
  CANCELADO_OPERADOR: 9,
};

// EQUIPOS

export const EQUIPMENT_STATES = {
  RECUPERADO: 1,
  RECUPERADO_L: 2,
  NEGATIVO_L: 3,
  AUTORIZAR: 4,
  PACTADO: 20,
  ENTREGADO_EN_SUCURSAL: 21,
};
