<template>
   <Base-modal
   :modalOverlay="modalOverlay"
   type="auto-screen"
   @exit="$emit('exit')"
   :modal="modal"
   :headingTitle="headingTitle">
        <template  v-slot:content>
              <slot name="content">
                  <div class="m-4">
                      <div class="flex flex-row flex-wrap ">
                          <div class="mr-2 btn-confirm">
                              <BaseButton
                              @click="$emit('cancel')"
                              customClass="border-none"
                              background="bg-red-400"
                              textColor="text-white"
                              :text="$t(back)"
                              />
                          </div>
                          <div class="btn-confirm">
                              <BaseButton
                              @click="$emit('confirm')"
                              :loading="loadingConfirm"
                              :disabled="disabledConfirm"
                              customClass="border-none"
                              background="bg-green-400"
                              textColor="text-white"
                              :text="$t(success)"
                              />
                          </div>
                      </div>
                  </div>
              </slot>
        </template>
    </Base-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    modalOverlay: {
      type: Boolean,
      default: false,
    },
    headingTitle: {
      type: String,
      default: 'Confirmar',
    },
    back: {
      type: String,
      default: 'Atras',
    },
    success: {
      type: String,
      default: 'Aceptar',
    },
    loadingConfirm: {
      type: Boolean,
      default: false,
    },
    disabledConfirm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

.btn-confirm{
    min-width: 5rem;
}

</style>
