<template>
    <div class="BaseInput">
      <label @click="activeFromLabel" :class="classLabel" >{{placeholder}}</label>
      <input
      ref="BaseInput"
      @input="setInput($event.target.value)"
      :value="modelValue"
      @focus="focused = true"
      @blur="activeBlur($event.target.value)"
      :class="[`${classInput}`, 'c-text-300']"
      :type="type ?? 'text'"
      autocomplete="new-user"
      :disabled="disabled"
      >

      <!-- este es el removedor de valor al costado derecho -->
      <template v-if="remove && hasValue">
        <!-- si el input es tipo date la clase es diferente por la posicion de el removedor, solo eso. -->
        <div @click="removeValues()" :class="[type === 'date' ? 'removeDate' : 'remove']">
          <font-awesome-icon class="text-gray-500" icon="times" />
        </div>
      </template>

      <div v-if="hasError" class="c-text-red font-bold text-xs mt-1 ml-3">{{error}}</div>
    </div>
</template>

<script>
// @group Input

export default {
  emits: ['update:modelValue', 'actionBlur', 'errorHandle'],
  props: {
    // tipo de input
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: '',
    },
    // placeholder
    placeholder: {
      type: String,
      required: true,
    },
    // color del outline al seleccionar
    outline: {
      // valores permitidos base,blue,rojo. Serian esta clase  active-label-base
      type: String,
      required: true,
    },
    // EL valor que devuelve
    modelValue: {
      type: [Object, String, Number],
    },
    // Evento
    actionBlur: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // activa el remove del select
    remove: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    classInput() {
      // si tiene foco, o si no tiene foco pero el input no esta vacio
      if (this.focused || (!this.focused && this.hasValue)) {
        // si no tiene error, le aplicamos las clases de un input activo
        if (!this.hasError) {
          return `BaseInput__input border-${this.outline} active-input`;
        }
        // si entra aqui es porque el input tiene algun error

        return 'BaseInput__input border-error active-input';
      }

      // si tiene error, le aplicamos las clases de un input con error, sin foco
      if (this.hasError) {
        return 'BaseInput__input border-error active-input';
      }

      // aca el input esta normal
      if (this.outline === 'transparent') {
        return 'BaseInput__input border-transparent';
      }
      return `BaseInput__input border-base ${this.type === 'date' || this.type === 'datetime' ? 'active-input' : ''}`;
    },
    classLabel() {
      if (this.focused || (!this.focused && this.hasValue)) {
        return `BaseInput__label ${this.hasError ? 'active-label-error' : `active-label-${this.outline}`}`;
      }
      if (this.type === 'date' || this.type === 'datetime') {
        return `BaseInput__label ${this.hasError ? 'active-label-error' : 'active-label-passive'}`;
      }
      return 'BaseInput__label';
    },
    hasError() {
      return this.error && this.error !== '';
    },
    hasValue() {
      if (this.modelValue && this.modelValue !== '') {
        return true;
      }
      return false;
    },
    placeHolderTitle() {
      return this.placeholder.replace(/\s/g, '').replace(/[^\w\s]/gi, '');
    },
  },
  data() {
    return {
      value: '',
      focused: false,
      invalidRule: false,
    };
  },
  methods: {
    activeFromLabel() {
      if (!this.focused) {
        this.$refs.BaseInput.focus();
      }
    },
    setInput(val) {
      if (this.rules.length > 0) {
        this.handleRules(val);
      }

      this.$emit('update:modelValue', val);
    },
    activeBlur(val) {
      this.focused = false;
      if (this.actionBlur) {
        this.$emit('actionBlur', val);
      }
      if (this.rules.length > 0) {
        this.handleRules(val);
      }
    },
    handleRules(val) {
      if (this.rules.length > 0) {
        for (let i = 0; i < this.rules.length; i += 1) {
          if (typeof this.rules[i] === 'function') {
            const invalid = this.rules[i](val);
            if (invalid) {
              this.invalidRule = true;
              this.$emit('errorHandle', invalid);
              break;
            } else {
              this.invalidRule = false;
            }
          } else if (typeof this.rules[i] === 'object') {
            if (Array.isArray(this.rules[i])) {
              console.log('aca validas el array');
            } else {
              if (Object.prototype.hasOwnProperty.call(this.rules[i], 'required')) {
                if (!val || val === undefined || val === null) {
                  this.invalidRule = true;
                  this.$emit('errorHandle', 'Campo obligatorio');
                  break;
                } else if (this.invalidRule) {
                  this.invalidRule = false;
                  this.$emit('errorHandle', '');
                }
              }
              if (val && Object.prototype.hasOwnProperty.call(this.rules[i], 'min')) {
                if (val.length < this.rules[i].min) {
                  this.invalidRule = true;
                  this.$emit('errorHandle', `Longitud minima ${this.rules[i].min}`);
                  break;
                } else if (this.invalidRule) {
                  this.invalidRule = false;
                  this.$emit('errorHandle', '');
                }
              }
              if (val && Object.prototype.hasOwnProperty.call(this.rules[i], 'max')) {
                if (val.length < this.rules[i].max) {
                  this.invalidRule = true;
                  this.$emit('errorHandle', `Longitud maxima ${this.rules[i].max}`);
                  break;
                } else if (this.invalidRule) {
                  this.invalidRule = false;
                  this.$emit('errorHandle', '');
                }
              }
            }
          }
        }
      }
    },
    errorHandle(val) {
      if (val && this.hasError && !this.invalidRule) {
        if (this.type === 'text' || this.type === 'number' || this.type === 'password' || this.type === 'email') { this.$emit('errorHandle', ''); }
      }
    },
    removeValues() {
      this.$emit('update:modelValue', '');
    },
  },
  watch: {
    modelValue(val) {
      this.errorHandle(val);
    },
  },
};
</script>

<style >
  .BaseInput{
    position: relative;
    display:block;
    height: 100%;
     max-width: 100%;
  }
  .BaseInput__input{
    outline: none;
    border-radius: 7px;
    height:52px;
    max-width: 100%;
    width:100%;
    padding:0 10px;
    font-size: 12px;
    font-weight: 400;
    background:white !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .BaseInput__label{
   position: absolute;
   top: 17px;
   left: 16.5px;
   font-size: 12px;
   transition: all .3s;
   color: var(--gray);
   cursor: text;
  }

  .active-label-base{
    top: 4px;
    transition: all .3s;
    color: var(--gray);
  }

  .active-label-passive{
    top: 4px;
    transition: all .3s;
  }

  .active-label-blue{
    top: 4px;
    transition: all .3s;
    color: var(--blue);
  }

  .active-label-black{
    top: 4px;
    transition: all .3s;
    color: var(--black);
  }

   .active-label-error{
    top: 4px;
    transition: all .3s;
    color: var(--red);
  }

  .active-label-transparent{
    top: 4px;
    transition: all .3s;
    /* color: var(--gray); */
  }

  /* border */

  .border-base {
    border: 1px solid rgb(215, 214, 218);
  }
  .border-blue {
  border: 1.2px solid var(--blue);
  }
  .border-black {
  border: 1.2px solid var(--black);
  }
  .border-error {
  border: 1.2px solid var(--red);
  }
  .border-transparent {
  border: none;
  }

  .active-input{
    padding-top: 20px;
  }
  .remove{
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }

  .removeDate{
    position: absolute;
    top: 22.5px;
    right: 55px;
    cursor: pointer;
  }

</style>
