<template>
  <div :class="formClass">
        <template v-for="(input, index) in getForm" :key="index">
            <div :class="[input.col ?? 'c-col-s-12', input.class ?? 'm-1']">
                <template v-if="input.onlyTitle">
                  <div v-if="input.title" :class="input.titleClass">
                      {{input.title}}
                  </div>
                </template>
                <template v-else>
                  <slot v-if="input.slot" :name="input.slot" ></slot>
                  <div v-if="input.title" :class="input.titleClass">
                      {{input.title}}
                  </div>
                  <!-- input normal  -->
                  <BaseInput
                      v-if="(!input.type || input.type === 'number' || input.type == 'password' || input.type === 'date')"
                      :type="input.type ?? 'text'"
                      :placeholder="input.placeholder ?? input.key"
                      :outline="input.outline ?? 'blue'"
                      v-model:modelValue="returnForm[input.key]"
                      :rules="input.rules"
                      :disabled="input.disabled"
                      :error="generateForm[index].error"
                      @error-handle="generateForm[index].error = $event"
                  />
                  <!-- select normal -->
                  <BaseSelect
                      v-else-if="input.type === 'select' && !input.slot"
                      :placeholder="input.placeholder ?? input.key"
                      :options="input.options ?? []"
                      v-model:modelValue="returnForm[input.key]"
                      :error="generateForm[index].error"
                      :trackBy="input.trackBy ?? 'id'"
                      :trackByString="input.trackByString ?? ''"
                      :label="input.label"
                      :keyName="input.keyName"
                      :outline="input.outline ?? 'blue'"
                      :disabled="input.disabled"
                      :remove="input.remove"
                      :returnObject="input.returnObject"
                      :returnAfterMatch="input.returnAfterMatch"
                      :filters="input.filters"
                      :type="input.selectType"
                      @error-handle="generateForm[index].error = $event"
                      @content-change="input.contentChange &&  input.contentChange === 'fire' ? getValues() : input.contentChange &&  typeof input.contentChange === 'function' ? input.contentChange($event) : ''"
                  />
                  <!-- select con slot -->
                  <BaseSelect
                      v-else-if="input.type === 'select' && input.slotSelect"
                      :placeholder="input.placeholder ?? input.key"
                      :options="input.options ?? []"
                      v-model:modelValue="returnForm[input.key]"
                      :error="generateForm[index].error"
                      :trackBy="input.trackBy ?? 'id'"
                      :trackByString="input.trackByString ?? ''"
                      :label="input.label"
                      :keyName="input.keyName"
                      :outline="input.outline ?? 'blue'"
                      :disabled="input.disabled"
                      :remove="input.remove"
                      :returnObject="input.returnObject"
                      :returnAfterMatch="input.returnAfterMatch"
                      :filters="input.filters"
                      :type="input.selectType"
                      @error-handle="generateForm[index].error = $event"
                      @content-change="input.contentChange ? input.contentChange($event) : ''"
                  >
                      <template v-slot:itemList="{item,index}">
                          <slot name="itemList" v-bind="{item,index}" ></slot>
                      </template>
                  </BaseSelect>
                  <!-- switch  -->
                  <Switch
                  v-if="input.type === 'switch'"
                  v-model="returnForm[input.key]"
                  :value="input.optionValue"
                  :label="input.label"
                  :trackBy="input.trackBy"
                  />

                </template>
            </div>
        </template>
  </div>
</template>

<script>
import Switch from '@/components/Form/Switch.vue';

export default {
  components: {
    Switch,
  },
  // RECIBE UN OBJETO CON TODOS LOS OBJETOS A RENDERIZAR EN EL FORM
  // {
  //   name: {
  //     type: 'number|text|datetime|select|switch',
  //     placeholder: 'Nombre',
  //     label: 'name', esto es para select, es la key a mostrar nombre del objeto seleccinoado
  //     optionValue: Object,Array,Boolean,String =>  valor del switch,
  //     value: '' , si quieres setearle un valor por defecto al input,si no, mandalo vacio,
  //     class:"clase-1"
  //     col: "c-col-s-6",
  //     hidden: Boolean,
  //     options: Array, para type select,
  //     slot: 'nombre del slot',
  //     slotSelect: 'nombre del slot del select'
  //  PARA MAS INFORMACION, REVISA LOS PROPS QUE RECIBE CADA COMPONENTE DEL TEMPLATE
  //   }
  // }
  props: {
    formClass: {
      type: String,
      default: 'main-form',
    },
    inputs: {
      type: [Array, Object],
    },
    returnObject: {
      // si viene true, el objeto a devolver tiene este formato  name:{ value: 'jose', error: '' }, si no, name:'jose'
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getForm() {
      Object.keys(this.inputs).forEach((k) => {
        const index = this.generateForm.map((m) => m.key).indexOf(k);
        // si ya existe el input
        if (index > -1) {
          // mergeo lo que tengo con lo que viene
          this.generateForm[index] = { ...this.generateForm[index], ...this.inputs[k] };
          if (this.inputs[k].value && this.inputs[k].value !== undefined) {
            this.returnForm[k] = this.inputs[k].value;
          }
        } else {
          // si no existe, agrego el input
          this.generateForm.push({ ...this.inputs[k], ...{ key: k } });
          if (!this.inputs[k].onlyTitle) {
            Object.defineProperty(this.returnForm, k,
              {
                enumerable: true,
                configurable: true,
                writable: true,
                value: {},
              });

            // si viene con valor, lo seteo
            if (this.inputs[k].value) {
              this.returnForm[k] = this.inputs[k].value;
            } else {
              this.returnForm[k] = null;
            }
            // }
          }
        }
      });
      return this.generateForm.filter((val) => val.hidden === undefined || val.hidden === false);
    },
    valid() {
      let result = true;
      const hasRequired = Object.keys(this.inputs).some((v) => this.inputs[v].rules);
      if (hasRequired) {
        for (let i = 0; i < this.generateForm.length; i += 1) {
          if (this.inputs[this.generateForm[i].key].rules && this.inputs[this.generateForm[i].key].rules.some((f) => f.required)) {
            if (Object.prototype.hasOwnProperty.call(this.returnForm, this.generateForm[i].key)) {
              if (typeof this.returnForm[this.generateForm[i].key] === 'object' && this.returnForm[this.generateForm[i].key]) {
                if (Object.prototype.hasOwnProperty.call(this.returnForm[this.generateForm[i].key], 'value')) {
                  if (!this.returnForm[this.generateForm[i].key].value || this.generateForm[i].error) {
                    result = false;
                    break;
                  }
                }
              } else if (typeof this.returnForm[this.generateForm[i].key] === 'string' || typeof this.returnForm[this.generateForm[i].key] === 'number') {
                if (!this.returnForm[this.generateForm[i].key] || this.generateForm[i].error) {
                  result = false;
                  break;
                }
                // verifico que no sea boolean, para que cuando el input este en falso, no entre en la condicion, si es false, es porque esta seteado
              } else if (!this.returnForm[this.generateForm[i].key] && typeof this.returnForm[this.generateForm[i].key] !== 'boolean') {
                result = false;
                break;
              }
            } else if (!this.returnForm[this.generateForm[i].key] || this.generateForm[i].error) {
              result = false;
              break;
            }
          }
        }
      } else {
        result = true;
      }

      return result;
    },
  },
  data() {
    return {
      generateForm: [],
      returnForm: {},
    };
  },
  methods: {
    getValues() {
      return new Promise((resolve) => {
        if (!this.returnObject) {
          this.$emit('getValues', this.returnForm);
          this.$nextTick(() => {
            resolve(this.returnForm);
          });
        } else {
          const object = {};
          for (let i = 0; i < this.generateForm.length; i += 1) {
            if (!this.generateForm[i].onlyTitle) {
              const valueInput = {};
              valueInput.value = null;
              valueInput.error = null;
              // if (this.generateForm[i].source) {
              //   valueInput.source = null;
              // }
              Object.defineProperty(object, this.generateForm[i].key,
                {
                  enumerable: true,
                  configurable: true,
                  writable: true,
                  value: valueInput,
                });
              // if (this.generateForm[i].source) {
              //   object[this.generateForm[i].key].value = this.returnForm[this.generateForm[i].key].value;
              //   object[this.generateForm[i].key].source = this.generateForm[i].source;
              // }
              object[this.generateForm[i].key].value = this.returnForm[this.generateForm[i].key];
              object[this.generateForm[i].key].error = this.generateForm[i].error;
            }
          }
          this.$emit('getValues', object);
          this.$nextTick(() => {
            resolve(object);
          });
        }
      });
    },
    setValue(payload) {
      // esta funcion es para setear especificamente una propiedad
      // pero con setear el objeto padre PROPS basta
      if (payload.key) {
        this.returnForm[payload.key] = payload.value ?? null;
      }
    },
    reset() {
      return new Promise((resolve) => {
        const keys = Object.keys(this.returnForm);
        for (let i = 0; i < keys.length; i += 1) {
          if (this.returnObject) {
            this.returnForm[keys[i]].value = null;
            this.returnForm[keys[i]].error = null;
          } else {
            this.returnForm[keys[i]] = null;
          }
        }
        this.$emit('getValues', this.returnForm);
        this.$nextTick(() => {
          resolve(this.returnForm);
        });
      });
    },
  },
};
</script>

<style scoped>

.main-form{
  display:grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

</style>
