import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  COLLECTOR, OPERATOR, COMMERCE, ZONE_OPERATOR, ADMIN,
} from '@/services/Utils/Role';
import { USER_STATES } from '@/services/Utils/State';

export default () => {
  // Esta funcion mapea los estados del usuario vas utilizados
  // Utiliza el store vuex para ello.
  const route = useRoute();
  const store = useStore();
  const isViewMode = computed(() => {
    if (route.path.search('view') > -1 || route.path.search('ver') > -1) return true;
    return false;
  });

  const isEditMode = computed(() => {
    if (route.path.search('edit') > -1 || route.path.search('editar') > -1) return true;
    return false;
  });

  const isZoneOperator = computed(() => {
    const { role_id } = store.getters['auth/user'];
    if (!role_id) return false;
    if (!ZONE_OPERATOR(role_id)) return false;
    return true;
  });

  const isAdmin = computed(() => {
    const { role_id } = store.getters['auth/user'];
    if (!role_id) return false;
    if (!ADMIN(role_id)) return false;
    return true;
  });

  const isCommerce = computed(() => {
    const { role_id } = store.getters['auth/user'];
    if (!role_id) return false;
    if (!COMMERCE(role_id)) return false;
    return true;
  });

  const isCollector = computed(() => {
    const { role_id } = store.getters['auth/user'];
    if (!role_id) return false;
    if (!COLLECTOR(role_id)) return false;

    return true;
  });

  const isOperator = computed(() => {
    const { role_id } = store.getters['auth/user'];
    if (!role_id) return false;
    if (!OPERATOR(role_id)) return false;
    return true;
  });

  const isActive = computed(() => {
    const { user_state_id } = store.getters['auth/user'];

    if (!user_state_id) return false;
    if (user_state_id !== USER_STATES.ACTIVO) return false;
    return true;
  });

  const isCompleted = computed(() => {
    const { user_state_id } = store.getters['auth/user'];
    if (!user_state_id) return false;
    if (user_state_id !== USER_STATES.COMPLETADO) return false;
    return true;
  });

  const isPending = computed(() => {
    const { user_state_id } = store.getters['auth/user'];
    if (!user_state_id) return false;
    if (user_state_id !== USER_STATES.PENDIENTE) return false;
    return true;
  });

  const checkPermission = (permission) => store.getters['auth/checkPermission'](permission);

  const getCurrentUser = computed(() => store.getters['auth/user']);

  return {
    isViewMode, isEditMode, isCommerce, isCollector, isAdmin, isZoneOperator, isOperator, isActive, isCompleted, isPending, getCurrentUser, checkPermission,
  };
};
