'strict mode';

import checkToken from '@/middleware/Authentication/CheckToken';
import Unauthenticated from '@/middleware/Authentication/Unauthenticated';
import { abilities } from '@/middleware/Authentication/Abilities';

async function guard(to, from, router, store) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // ESTE METODO DEBO SACARLE MODIFICAR PARA QUE EL TOKEN LO RECIBA DESDE EL Localstorage
    await checkToken(to, from, router, store);
  }

  if (to.matched.some((record) => record.meta.gate)) {
    await abilities(to, from, router, store);
  }

  if (to.matched.some((record) => record.meta.unauthenticated)) {
    await Unauthenticated(to, from, router, store);
  }
}

export default guard;
