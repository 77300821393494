<template>
  <div :class="[`bg-${color}-100 ${border} border-${color}-500 rounded-b text-${color}-900 px-4 py-3 shadow-md`]" role="alert">
      <div class="flex">
        <div class="py-1">
          <font-awesome-icon :class="[`fill-current h-6 w-6 text-${color}-500 mr-4`]" :icon="icon" />
        </div>
        <div>
            <slot name="text"></slot>
            <slot name="subText"></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default() {
        return 'green';
      },
    },
    border: {
      type: String,
      default() {
        return 'border-t-4';
      },
    },
    icon: {
      type: String,
      default() {
        return 'exclamation-circle';
      },
    },
  },
};
</script>

<style>

</style>
