const Settings = () => import(/* webpackChunkName: "Admin" */ '@/views/Settings/Settings.vue');
const SettingsRoute = [
  {
    path: '/settings',
    name: 'Settings',
    meta: { requiresAuth: true },
    component: Settings,
  },
];
export default SettingsRoute;
