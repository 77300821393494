<template>
    <div class="my-2 flex flex-col justify-between h-full" >
        <div>
          <div class="ml-4 my-4 flex flex-row justify-between">
            <img class="w-28 " :src="BaseUrl + '/images/logo/devuelvoya.png'" />
            <div @click="setSidebar()" class="mr-5 cursor-pointer">
                <font-awesome-icon :class="[`c-text-${iconColor}`]" icon="bars" />
            </div>
          </div>
          <div>
            <LisItemFine 
            v-if="isAdmin || isZoneOperator"
            customClass="my-1" :link="false" :multi-link="true" icon="user" :text="$t('Usuarios')"
            :fullRoutes="[
              {href: reclutamiento, text: $t('Reclutamiento')},
            ]"/>
            <LisItemFine 
            v-if="isAdmin || isZoneOperator"
            customClass="my-1" 
            :link="false" 
            :multi-link="true" 
            icon="layer-group" 
            :text="$t('Asignación')"
            :fullRoutes="[
              {name:'Coverages', text:'Zonas'},
              {name:'EmptyAreas', text:'Zonas vacias'},
            ]"/>
            <LisItemFine 
            v-if="isAdmin || isZoneOperator || isOperator"
            customClass="my-1" :link="false" :multi-link="true" icon="phone" :text="$t('Call')"
            :fullRoutes="[
            {name:'agreements', text: $t('Pactados')},
              {name:'agreeCustomer', text: $t('Pactar') },
            ]"/>
            <LisItemFine 
            v-if="isAdmin || isZoneOperator"
            customClass="my-1"  :link="false" :multi-link="true" icon="archive" :text="$t('Equipos')"
            :fullRoutes="[
            {name:'customers', text: $t('Gestionar equipo') },
            {name:'historyEquipmentManagements', text: $t('Equipos gestionados') },
            {name:'historyEquipments', text: $t('Clientes') },
            ]"/>
            <LisItemFine
            v-if="isAdmin || isZoneOperator || isOperator"
             customClass="my-1" :fullRoute="{name:'Dashboard'}" icon="solar-panel" :text="$t('Panel')"/>
           
            <LisItemFine 
            v-if="checkPermission('empresa_listar_todos') && checkPermission('sucursal_empresa_listar_todos')" 
            customClass="my-1" 
            :link="false" 
            :multi-link="true" 
            icon="building" 
            :text="$t('Empresas')"
            :fullRoutes="[
            {href: usuariosEmpresas , text: $t('Usuarios') },
            {href: sucursales, text: $t('Sucursales') },
            ]"/>

            <LisItemFine 
            v-if="isAdmin || isZoneOperator || isOperator"
            customClass="my-1" :fullRoute="{href:colectas}" icon="car-side" :text="$t('Colectas')"/>

            <LisItemFine 
            v-if="isAdmin || isZoneOperator || isOperator"
            customClass="my-1" :link="false" :multi-link="true"
            :fullRoutes="[
              {name:'Settings', text: $t('Cuenta') },
              ...(checkPermission('equipment_import') ? [{name:'importEquipment', text: $t('Importar equipos') }] : []),
              ...(checkPermission('documento_dinamico_listar_todos') ? [{href: documentos, text: $t('Documentos') }] : []),
            ]"
            icon="cog" :text="$t('Configuracion')"/>
            <LisItemFine 
            v-if="isAdmin || isZoneOperator"
            customClass="my-1" :fullRoute="{href: dashboardOperaciones}" icon="key" :text="$t('Portal Empresas')"/>
            <LisItemFine customClass="my-1"  :fullRoute="{name:'Logout'}" icon="sign-in-alt" :text="$t('Cerrar Sesion')"/>
          </div>
        </div>
        <div class="ml-4 mb-2">
          <span class=" c-text-white">{{user.firstName}} {{user.lastName}}</span>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import baseUrl from '@/services/Api/BaseUrl';
import LisItemFine from '@/components/Core/List/ListItemFine.vue';
import coreHook from '@/hooks/core/coreHook';

export default {
  components: {
    LisItemFine,
  },
  props: {
    iconColor: {
      type: String,
      default: 'gray',
    },
    textColor: {
      type: String,
      default: 'white',
    },
  },
  setup() {
    const store = useStore();
    const BaseUrl = baseUrl.apiDevuelvoya;
    const BaseUrlClient = process.env.VUE_APP_CLIENTE;
    const usuariosEmpresas = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${store.state.auth.token}&redirectTo=/usuarios/usuarios-empresas`;
    const reclutamiento = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${store.state.auth.token}&redirectTo=/usuarios/usuarios-operativos`;
    const dashboardOperaciones = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${store.state.auth.token}&redirectTo=/dashboard`;
    const documentos = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${store.state.auth.token}&redirectTo=/configuraciones/documentos`;
    const sucursales = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${store.state.auth.token}&redirectTo=/empresas/sucursales-empresas`;
    const colectas = `${process.env.VUE_APP_OPERACIONES_CONTROLADOR}?token=${store.state.auth.token}&redirectTo=/colecta/colecta-construccion`;
    
    const user = computed(() => store.state.auth.user);
    const setSidebar = () => {
      store.dispatch('sidebar/setSidebar', false);
    };

    const { isAdmin, checkPermission, isOperator, isZoneOperator } = coreHook();
    //
    return {
      BaseUrl, 
      setSidebar, 
      user, 
      isAdmin, 
      isZoneOperator,
      isOperator,
      checkPermission, 
      BaseUrlClient,
      dashboardOperaciones,
      reclutamiento,
      usuariosEmpresas,
      documentos,
      sucursales,
      colectas
    };
  },
};
</script>
