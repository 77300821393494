<template>
  <div>
        <template v-if="type === 'event' ||  type === 'file'">
            <button :style="disabled ? 'opacity:0.6' : ''" :disabled="disabled" @click="handleClick()" :class="[`${border}`,`${background}`,`${padding}`,`${textColor}`, `${rounded}`,`${customClass}`, 'w-full', 'flex justify-center']">
                <template v-if="loading">
                <svg class="animate-spin w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                </template>
                <template v-else>
                  <div class="flex flex-row">
                    <div v-if="icon !== '' && iconLeft" :class="[text ? 'mr-1' : '']">
                      <font-awesome-icon :class="iconColor" :icon="icon" />
                    </div>
                    <div>
                      {{text}}
                    </div>
                    <div v-if="icon !== '' && !iconLeft" :class="[text ? 'ml-1' : '']">
                      <font-awesome-icon :class="iconColor" :icon="icon" />
                    </div>
                  </div>
                </template>
            </button>
        </template>
        <template v-if="type === 'route'">
            <router-link :to="fullRoute" :class="[`${border}`,`${background}`,`${padding}`,`${textColor}`, `${rounded}`,`${customClass}`, 'w-full', 'flex justify-center']">
                <div class="flex flex-row">
                  <div>
                    {{text}}
                  </div>
                  <div v-if="icon !== ''" class="ml-2">
                    <font-awesome-icon :class="iconColor" :icon="icon" />
                  </div>
                </div>
            </router-link>
        </template>
        <template v-if="type === 'file'">
          <input  v-show="false" @click="resetEvent" :value="modelValue" @change="uploadFile" type="file" ref="refFile">
        </template>
  </div>
</template>

<script >
export default {
  emits: ['click', 'eventFile'],
  props: {
    text: {
      type: String,
    },
    textColor: {
      type: String,
      default: 'text-black',
    },
    background: {
      type: String,
      default: 'bg-transparent',
    },
    padding: {
      type: String,
      default: 'p-1 px-2',
    },
    customClass: {
      type: String,
      default: '',
    },
    border: {
      type: String,
      default: 'border border-gray-400',
    },
    rounded: {
      type: String,
      default: 'rounded',
    },
    type: {
      type: String,
      default: 'event',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullRoute: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'text-gray-400',
    },
    // EL valor que devuelve
    modelValue: {
      type: [Object, String, Number],
    },
  },
  methods: {
    handleClick() {
      if (this.type === 'event') {
        this.$emit('click');
      }
      if (this.type === 'file') {
        this.$refs.refFile.click();
      }
    },
    resetEvent() {
      this.$refs.refFile.value = '';
    },
    uploadFile(event) {
      this.$emit('eventFile', event.target.files[0]);
    },
  },
};

</script>
