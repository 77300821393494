<template>
  <div :style="customStyle" >
    <div class="my-1 mb-3 flex flex-row justify-between">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <!-- <BaseInput :modelValue="search" @modelValue="writing($event)"/> -->
          <BaseInput
          v-if="searchFilter"
          :placeholder="$t('Buscar')"
          outline="transparent"
          v-model:modelValue="searchValue"
          />
          <!-- v-model:modelValue="filters.createdStart.value" -->
          <template v-if="loading && loaderSearch">
            <div class="ml-2">
              <LoaderSpinner height="20" spinnerColor="text-gray-400"/>
            </div>
          </template>
          <template v-else>
            <div @click="applyFilter()" class="ml-2 cursor-pointer">
              <font-awesome-icon class="text-gray-400" icon="sync" />
            </div>
          </template>
        </div>
        <div v-if="exportable" class="my-2">
          <font-awesome-icon @click="exportExcel()" class="cursor-pointer text-green-600" icon="file-excel" />
        </div>
      </div>
      <div  class="flex flex-col  xl:flex-row xl:flex-wrap">
        <slot name="subContent"></slot>
        <div v-if="filter"  :class="[objEmpty(formFilter) ? 'relative': '','sm:justify-center flex sm:justify-end ']">
          <template v-if="objEmpty(formFilter)">
            <div class="w-32 mr-1">
              <BaseButton  @click="openFilter()" text="Filtrar" />
            </div>
            <BaseRightBar :rightBar="filterDisplay">
              <template v-slot:contentBar>
                <div class="p-2">
                  <div class="text-center">
                      <span @click="openFilter()" class="bg-gray-300 text-white px-2 rounded-xl cursor-pointer" >x</span>
                  </div>
                  <div class="filter-express">
                    <slot name="filter" ></slot>
                  </div>
                    <div class="my-2 flex flex-row justify-between">
                      <div class="w-20">
                        <BaseButton :loading="loading" background="transparent" textColor="text-black" customClass="border border-gray-200" :text="$t('Aplicar')" @click="applyFilter()" />
                      </div>
                      <BaseButton background="transparent" textColor="text-black" customClass="border border-gray-200" text="Remover filtros" @click="removeFilter()" />
                    </div>
                </div>
              </template>
            </BaseRightBar>
           </template>
           <template v-else>
             <Menu
                :overlay="true"
                @exit="filterDisplay = false"
                :menu="filterDisplay"
                :cache="true"
                >
                  <template v-slot:activator>
                    <div @click="openFilter()" class=" cursor-pointer w-32">
                      <BaseButton  text="Filtrar" />
                    </div>
                  </template>
                  <template v-slot:menuContent>
                    <div class="bg-white rounded-lg main-filter">
                      <div class="flex justify-between my-2 mx-4">
                        <div class="font-bold"> Filtrar por:</div>
                        <div><font-awesome-icon @click="filterDisplay = false" class="cursor-pointer" icon="times" /></div>
                      </div>
                      <hr class="mt-3 mb-4">
                      <FormExpress formClass="m-2" ref="refFormFilter"  @getValues="$emit('getValuesFilter',$event)" :inputs="formFilter"/>
                      <div class="flex flex-row flex-wrap my-2 mx-3 justify-between">
                        <div  class="w-28">
                          <BaseButton @click="prepareFilter({},false,true)" :disabled="loading || loading" :text="$t('Aplicar')" />
                        </div>
                        <div class="w-28">
                          <BaseButton @click="removeFilter()" :disabled="loading || loading" :text="$t('Remover')"/>
                        </div>
                      </div>
                    </div>
                  </template>
                </Menu>
           </template>
        </div>
      </div>
    </div>
    <BaseTable v-if="alwaysVisible" :count="count" :loading="loading" @lowerLimit="lowerLimit()" :response="response">
      <template v-slot:columns>
        <slot name="columns" ></slot>
      </template>
    </BaseTable>
  </div>

</template>

<script>

import BaseRightBar from '@/components/Core/Bar/BaseRightBar.vue';
import { addParamsToRoute } from '@/services/Utils/Router';
import { objEmpty, removeDuplicates } from '@/services/Utils/Objects';
import BaseTable from '@/components/Core/Table/BaseTable.vue';
import Menu from '@/components/Core/Bar/Menu.vue';
import LoaderSpinner from '@/components/General/LoaderSpinner.vue';
//
export default {
  components: {
    BaseTable, BaseRightBar, Menu, LoaderSpinner,
  },
  emits: ['setResponse','getResponse', 'completeFilters', 'removeFilters', 'notFound', 'getValuesFilter'],
  props: {
    // Repositorio donde hara las peticiones
    repository: {
      type: Function,
    },
    response: {
      type: Array,
      default() {
        return [];
      },
    },
    // El nombre del hash que viene de las peticiones
    model: {
      type: String,
      default: 'data',
    },
    // los filtros que vienen de afuera
    // Si es un string o number, deberas declarar keyFilter: 'value'
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    // define si la tablaExpress tendra componente filters
    filter: {
      type: Boolean,
      default: true,
    },
    searchFilter: {
      // define si la tabla tendra filtros buscador de texto
      type: Boolean,
      default: true,
    },
    shootFilter: {
      type: Boolean,
      default: false,
    },
    uniqueIndex: {
      // el index para pasarle a la funcion para removes los duplicados
      type: String,
      default: 'id',
    },
    removeDuplicates: {
      // para remover duplicados en cada response comparando con lo que tengo
      type: Boolean,
      default: false,
    },
    visibleWithOutResponse: {
      // este prop es para indicar si cuando la tabla no tiene datos, se debe mostrar la tabla o ocultar
      type: Boolean,
      default: true,
    },
    keySearch: {
      // esta es la key del buscador para enviar al backend
      type: String,
      default: 'search',
    },
    loaderSearch: {
      // loader al lado del buscador
      type: Boolean,
      default: false,
    },
    fetchBooted: {
      // indica si al montar el componente hay que inicializar la busqueda
      type: Boolean,
      default: true,
    },
    cacheParams: {
      // indica si al realizar busqueda con filtros, debe guardar los parametros en la url para al recargar o redireccionar, tenerlos activos o guardados en la url
      type: Boolean,
      default: true,
    },
    customStyle: {
      type: String,
      default: 'width:100%;max-width:100%;',
    },
    formFilter: {
      // los filtros para el formulario
      type: Object,
    },
    exportable: {
      type: Boolean,
      default: false,
    },

  },
  computed: {
    alwaysVisible() {
      if (this.visibleWithOutResponse) {
        return true;
      }
      if (this.response.length > 0) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      loading: false,
      stop: false,
      search: '',
      timer: 0,
      buildFilter: {},
      pagination: {},
      count: 0,
      searchValue: null,
      filterDisplay: false,
      localFilters: {},
      exportEnabled: false,
    };
  },
  methods: {
    async handleRequest() {
      if (!this.repository) console.error('Debes definir repositorio');
      if (this.fetchBooted) {
        const { query } = this.$route;
        const queryLength = Object.keys(query).length;
        // si hay parametros en query, aplico filtro
        if (queryLength > 0) {
          if (query.search) {
            this.search = query.search;
          }
          await this.applyFilter(query, true);
        } else {
          await this.getData();
        }
      }
    },
    async getData() {
      if (!this.loading) {
        if (!objEmpty(this.pagination) && !this.pagination.has_next_page) {
          return;
        }
        // el stop sirve para cuando la peticion no devuelva nada, indique que ya no debe ejecutar nuevamente la llamada
        this.loading = true;
        let params = {};
        // copio la metadata de la peticion para no mutar
        const pagination = { ...this.pagination };
        // calculo la pagina siguiente con la pagina actual de la paginationda
        const nextPage = Object.keys(pagination).length > 0 ? { page: pagination?.current_page + 1 } : false;

        if (this.search) params = { ...params, ...{ search: this.search } };

        if (this.buildFilter) params = { ...params, ...this.buildFilter };
        if (nextPage) params = { ...params, ...nextPage };
        const result = await this.repository(params);
        if (result[this.model]?.length > 0) {
          const data = result[this.model];
          const response = [...this.response, ...data];
          let cleanResponse;
          if (this.removeDuplicates) {
            cleanResponse = await removeDuplicates(response, this.uniqueIndex);
          } else {
            cleanResponse = response;
          }

          this.$emit('setResponse', cleanResponse);
          this.$emit('getResponse', result);
          addParamsToRoute(params, this.$route.path);

          if (result.meta) {
            this.pagination = result.meta;
            this.count = result.meta.total;
          } else if (result.pagination) {
            this.pagination = result.pagination;
            this.count = this.pagination.count;
          } else if (result.current_page) {
            this.pagination.current_page = result.current_page;
            this.pagination.last_page = result.last_page;
            this.pagination.count = result.total ? result.total : result.count;
            this.count = this.pagination.count;
          }
        }

        this.loading = false;
      }
    },
    async getFilterData() {
      // el stop sirve para cuando la peticion no devuelva nada, indique que ya no debe ejecutar nuevamente la llamada
      if (!this.loading) {
        // el timer es para reducir el rebote de las peticiones repetidas, solo eso...
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          this.loading = true;
          let params = {};

          if (this.search) { // si el BaseInput tiene algo escrito
            params = { ...{ [this.keySearch]: this.search } };
            // Reviso si el buildFilter ya contiene la propiedad search para eliminarla y setearsela nuevamente
            if (Object.prototype.hasOwnProperty.call(this.buildFilter, 'search')) {
              delete this.buildFilter.search;
            }
          }
          // Si buildFilter viene con filtros, seteo params, que es la variable encargada de contener los filtros unicamente en este metodo getFilterData()
          if (Object.keys(this.buildFilter).length > 0) {
            params = { ...params, ...this.buildFilter };
          }
          // siempre seteo la pagina con valor 1 al aplicar un filtro por primera vez. Porque en las proximas veces, el metodo getData() es el que maneja las request
          params = { ...params, ...{ page: 1 } };

          if (this.exportEnabled) {
            const result = await this.repository(params, true);
            const { path } = result;
            if (path) {
              window.open(`${this.$apiDevuelvoya}/storage/${path}`);
            }
            this.exportEnabled = false;
          } else {
            const result = await this.repository(params);

            // Si no esta declarado el modelo del hash, tomo como modelo el method del repository
            if (result[this.model]?.length > 0) {
              this.$emit('notFound', false);
              const data = result[this.model];
              this.clearResponse();
              const response = [...data];
              let cleanResponse;
              if (this.removeDuplicates) {
                cleanResponse = await removeDuplicates(response, this.uniqueIndex);
              } else {
                cleanResponse = response;
              }
              this.$emit('setResponse', cleanResponse);
              this.$emit('getResponse', result);

              if (result.meta) {
                this.pagination = result.meta;
                this.count = result.meta.total;
              } else if (result.pagination) {
                this.pagination = result.pagination;
                this.count = this.pagination.count;
              } else if (result.current_page) {
                this.pagination.current_page = result.current_page;
                this.pagination.last_page = result.last_page;
                this.pagination.count = result.total ? result.total : result.count;
                this.count = this.pagination.count;
              }
              // este stop es para que no vuelva a entrar en el ciclo nuevamente porque la peticion no arroja resultados
              this.stop = false;
            } else {
              this.stop = true;
              this.clearResponse();
              if (this.search || !objEmpty(this.buildFilter)) {
                this.$emit('notFound', true);
              }
            }

            if (this.cacheParams) {
              addParamsToRoute(params, this.$route.path);
            }
          }
          this.loading = false;
        }, 450);
      }
    },
    lowerLimit() {
      // Lower limit es el evento que se activa al hacer scroll en la tabla y estamos en la parte inferior
      this.getData();
    },
    clearResponse() {
      this.$emit('setResponse', []);
    },
    async applyFilter(routeParams = {}, ready = false) {
      if (!ready) { // ready significa que es la primera vez que renderiza la tabla sin filtros, si tiene filtros, no entra aqui.
        if (!objEmpty(this.filters) || !objEmpty(this.localFilters)) {
          this.localFilters = { ...this.localFilters, ...JSON.parse(JSON.stringify(this.filters)) };
          Object.keys(this.localFilters).forEach((k) => {
            // adentro de filter tengo el objeto con clave y valor
            if (this.localFilters[k]) {
              if (this.localFilters[k].key) {
                if (this.localFilters[k].value[this.localFilters[k].key] || this.localFilters[k].value[this.localFilters[k].key] === 0) {
                  this.buildFilter[k] = this.localFilters[k].value[this.localFilters[k].key];
                } else if (Object.prototype.hasOwnProperty.call(this.buildFilter, k)) {
                  // Si es undefined o no està la clave, verifico si esta  => clave,valor esta en los params para eliminarlo
                  delete this.buildFilter[k];
                }
              } else if (this.localFilters[k].value || this.localFilters[k].value === 0) {
                this.buildFilter[k] = this.localFilters[k].value;
              } else if (this.localFilters[k] && !Object.prototype.hasOwnProperty.call(this.localFilters[k], 'value')) {
                // aca entra si el valor no esta en value, key: { value: 'valor'}
                this.buildFilter[k] = this.localFilters[k];
              } else if (Object.prototype.hasOwnProperty.call(this.buildFilter, k)) {
                // Si es undefined o no està la clave, verifico si esta  => clave,valor esta en los params para eliminarlo
                delete this.buildFilter[k];
              }
            }
          });
        }
      } else {
        // aca entro desde el created(), porque hay filtros para aplicar
        this.buildFilter = routeParams; // buildFilter es el que maneja todos los filtros, TODOS!!
        this.$emit('completeFilters', this.buildFilter);
        // si el filtro construido contiene el filtro buscado de texto, se completa
        if (Object.prototype.hasOwnProperty.call(this.buildFilter, this.keySearch)) {
          this.searchValue = this.buildFilter[this.keySearch];
        }

        // si en los filtros esta page y es el unico, no tengo porque abrir el filtro
        if (Object.prototype.hasOwnProperty.call(this.buildFilter, 'page')) {
          if (Object.keys(this.buildFilter).length > 1) {
            this.openFilter();
          }
        }
      }
      if (Object.keys(this.buildFilter).length === 0) {
        // si no hay filtros, limpio la url

        if (this.cacheParams) {
          await addParamsToRoute({}, this.$route.path);
        }
      }
      this.getFilterData();
    },
    async removeFilter() {
      this.localFilters = {};
      this.buildFilter = {};
      if (objEmpty(this.formFilter)) {
        this.$emit('removeFilters', {});
        if (this.cacheParams) {
          await addParamsToRoute({}, this.$route.path);
        }
      } else {
        await this.$refs.refFormFilter.reset();
        this.buildFilter = {};
      }

      await this.getFilterData();
    },
    writing(val) {
      this.search = val;
      if (val) {
        this.getFilterData();
      } else {
        // seteo el loading falso por si acaso cuando el search este vacio e intente traer los datos
        // puede traerlos sin poblemas por el flag laoding
        this.loading = false;
        this.$nextTick(() => {
          this.getFilterData();
        });
      }
    },
    async prepareFilter() {
      this.buildFilter = {};
      this.localFilters = await this.$refs.refFormFilter.getValues();
      await this.applyFilter({}, false, true);
    },
    openFilter() {
      this.filterDisplay = !this.filterDisplay;
    },
    completeFilters() {
      this.$emit('completeFilters', this.buildFilter);
    },
    exportExcel() {
      if (this.loading) { return; }
      this.exportEnabled = true;
      this.$nextTick(() => {
        this.applyFilter();
      });
    },

  },
  watch: {
    searchValue(val) {
      this.writing(val);
    },
  },
  created() {
    this.handleRequest();
  },
  setup() {
    return { objEmpty };
  },
};
</script>

<style>

.filter-express{
  max-height:450px;
  overflow-y:auto;
}

@media (min-height:620px){
  .filter-express{
    max-height:500px;
  }
}

@media (min-height:720px){
  .filter-express{
    max-height:600px;
  }
}

.filter-express::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.filter-express::-webkit-scrollbar-thumb {
    background: #cfcece;
    -webkit-border-radius: 1ex;
    /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); */
}

.spinner{
  position: fixed;
  z-index: 4;
  top: 50%;
  bottom: 50%;
  width:280px;
}

.main-filter{
  max-width:280px;
  min-width:280px;
  max-height:300px;
  overflow-y:auto;
}

@media (min-height:420px){
  .main-filter{
    max-height:400px;
  }
}

@media (min-height:580px){
  .main-filter{
    max-height:450px;
  }
}
@media (min-height:720px){
  .main-filter{
    max-height:590px;
  }
}

</style>
