import { createI18n } from 'vue-i18n'

import ARG from '@/assets/lang/arg.json'
import PRY from '@/assets/lang/pry.json'
import COL from '@/assets/lang/col.json'
import URY from '@/assets/lang/ury.json'
import BRA from '@/assets/lang/bra.json'

const i18n = createI18n({
  // locale:  window.navigator.language.split('-')[0], // if you want the app to detect the browser's default language
  legacy: false,
  locale: 'arg', // default language
  fallbackLocale: 'arg', // fallback language in case a translation is not found
  messages: {
    arg: ARG,
    pry: PRY,
    ury: URY,
    col: COL,
    bra: BRA
  },
})

export default i18n