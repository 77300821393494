'strict mode';

import fetchAxios from '@/services/Api/AxiosInstance';
// import baseUrl  from '@/services/Api/BaseUrl';

const AuthenticationRepository = {
  async login(params) {
    const result = await fetchAxios.post('v1/authentication/login', params);
    return result;
  },
  async restorePassword(params) {
    const result = await fetchAxios.post('v1/authentication/restorePassword', params);
    return result;
  },
  async newPassword(params) {
    const result = await fetchAxios.post('v1/authentication/newPassword', params);
    return result;
  },
  async logout(params) {
    const result = await fetchAxios.get('v1/authentication/logout', params);
    return result;
  },
  async checkToken() {
    return fetchAxios.get('v1/authentication/checkToken');
  },
  async checkTokenUser() {
    return fetchAxios.get('v1/authentication/checkTokenUser');
  },
  async checkTokenRestore() {
    return fetchAxios.get('v1/authentication/checkTokenRestore');
  },
  async abilities() {
    return fetchAxios.get('v1/authentication/abilities');
  },
};

export const userAuthenticateRepo = async () => {
  let result = {};
  try {
    const response = await fetchAxios.get('v1/authentication/user');
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export default AuthenticationRepository;
