<template>
    <transition name="slide" appear>

    <!-- <transition name="fade" mode="out-in">
         <div  v-if="modal && modalOverlay" :class="['modal-overlay', `c-bg-${overlay}`]"></div>
    </transition> -->
       <template v-if="cache">
        <div :style="persistent? 'pointer-events: auto;':''" v-show="modal" :class="['modal-overlay', `c-bg-${overlay}`]">
            <div ref="modalContainer"  :class="[`${type === 'full-screen' ? 'full' : size}`,`modal-container-${type}` , `${border}`,`${shadow}`]">
                <slot name="header" >
                    <div :class="headingTitle !== '' ? 'header  justify-between': 'header justify-end'">
                        <template v-if="headingTitle !== ''">
                          <div class="font-semibold text-center my-2 ">{{headingTitle}}</div>
                        </template>
                        <div style="height:35px;width:35px;" @click="$emit('exit', false)" class="flex justify-center  icon-content ml-3">
                          <font-awesome-icon class="font-bold text-lg"  icon="times" />
                        </div>
                    </div>
                </slot>
                <div ref="modalContent">
                  <slot name="content" >
                  </slot>
                </div>
                <slot name="footer" v-if="footer">
                  </slot>
            </div>
        </div>
      </template>
      <template v-else>
        <div :style="persistent? 'pointer-events: auto;':''" v-if="modal" :class="['modal-overlay', `c-bg-${overlay}`]">
          <div ref="modalContainer"  :class="[`${type === 'full-screen' ? 'full' : size}`,`modal-container-${type}` , `${border}`,`${shadow}`]">
              <slot name="header" >
                  <div :class="headingTitle !== '' ? 'header  justify-between': 'header justify-end'">
                      <template v-if="headingTitle !== ''">
                        <div class="font-semibold text-center my-2 ">{{headingTitle}}</div>
                      </template>
                      <div style="height:35px;width:35px;" @click="$emit('exit', false)" class="flex justify-center  icon-content ml-3">
                        <font-awesome-icon class="font-bold text-lg"  icon="times" />
                      </div>
                  </div>
              </slot>
              <div ref="modalContent">
                <slot name="content" >
                </slot>
              </div>
              <slot name="footer" v-if="footer">
                </slot>
          </div>
        </div>
      </template>

  </transition>
</template>

<script>
export default {
  emits: ['exit'],
  props: {
    modalOverlay: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      required: true,
    },
    headingTitle: {
      type: String,
      default() {
        return '';
      },
    },
    footer: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: String,
      default: 'gray-light',
    },
    border: {
      type: String,
      default: '',
    },
    shadow: {
      type: String,
      default: 'shadow-lg',
    },
    type: {
      type: String,
      default: 'media-screen',
    },
    size: {
      type: String,
      default: 'md',
    },
    cache: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalHeight: null,
    };
  },
  methods: {
    handleResize(e) {
      const div = document.querySelector('.modal-content');
      if (div) {
        if (window.innerHeight - e[0].target.clientHeight < 150) {
          if (!this.$refs.modalContainer?.classList?.contains('scroll')) {
            if (this.$refs.modalContainer) {
              this.$refs.modalContainer.classList.add('scroll');
            }
          }
        } else if (this.$refs.modalContainer?.classList.contains('scroll')) {
          this.$refs.modalContainer.classList.remove('scroll');
        }
      }
    },
  },
  mounted() {
    if (this.modal && this.type === 'full-screen') {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    // if (this.$refs.modalContainer) {
    //   const resizer = new ResizeObserver(this.handleResize);
    //   resizer.observe(this.$refs.modalContainer);
    // }
  },
  watch: {
    modal(val) {
      if (val) {
        // setTimeout(() => {
        //   if (this.$refs.modalContainer) {
        //     const resizer = new ResizeObserver(this.handleResize);
        //     resizer.observe(this.$refs.modalContainer);
        //   }
        // }, 2000);
      } else if (this.type === 'full-screen') {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
      }
    },
  },
  beforeUnmount() {
    if (this.type === 'full-screen') {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  },

};
</script>

<style scoped>

.modal-overlay{
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1),z-index 1ms;
  width: 100%;
  z-index: var(--z-4);
  outline: none;
}

.xs{
  max-width: 450px;
}

.md{
  max-width: 600px;
}

.lg{
  max-width: 750px;
}

.xl{
  max-width: 850px;
}

.xll{
  max-width: 950px;
}

.full{
  max-width: 100%;
}

/* media screen */
.modal-container-auto-screen {

    border-radius: 7px;
    overflow-y: auto;
    pointer-events: auto;
    transition: .3s cubic-bezier(.25,.8,.25,1);
    width: auto;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    background:white;
    max-height: 100%;
    z-index: var(--z-4);
}

/* media screen */
.modal-container-media-screen {
    border-radius: 7px;
    overflow-y: auto;
    pointer-events: auto;
    transition: .3s cubic-bezier(.25,.8,.25,1);
    width: 100%;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    background:white;
    min-height: 100%;
    max-height: 100%;
    height: 100vh;
    z-index: var(--z-4);
}

/* full screen */
.modal-container-full-screen {

    overflow-y: auto;
    pointer-events: auto;
    transition: .3s cubic-bezier(.25,.8,.25,1);
    width: 100vw;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    background:white;
    max-height: 100%;
    min-height: 100%;
    height: 100vh;
    z-index: var(--z-4);
}

.header{

  display: flex;
  flex-direction: row;
  margin: 0.7rem 0px;
  padding-left: 10px;
  padding-right: 10px;

}

.icon-content{
  display:flex;
  align-items: center;
  height:1.2rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px;
  cursor: pointer;

}

.scroll{
  overflow-y: auto;
}

.icon {
  color:white;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 1rem;
}

 @media (min-width:620px){

   /* media screen */
  .modal-container-media-screen{
    /* position: fixed;
    min-height: 5rem;
    height:auto;
    margin: auto;
    width: auto;
    border-radius:17px; */
    border-radius: 7px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: .3s cubic-bezier(.25,.8,.25,1);
    width: 100%;
    z-index: inherit;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    background:white;
    max-height: 90%;
    height: auto;
    min-height: auto;
  }

}

.slide-enter-active, .slide-leave-active {
    transition: opacity .5s;

}

.slide-enter-from, .slide-leave-to{
    opacity:0;
    /* transform:translate(100vw,-50%); */

}

</style>
