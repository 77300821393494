<template>
      <transition name="fade" mode="out-in">
          <div @touchstart.passive="touchOverlay" v-if="sidebar" id="overlay-sidebar" class="overlay-sidebar"></div>
      </transition>
      <transition name="fade" mode="out-in">
        <div v-if="sidebar" :class="['sidebar',`c-bg-${background}`]" class="sidebar">
            <slot></slot>
        </div>
      </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    hideSideBar: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: 'black-shines',
    },
  },
  computed: {
    ...mapState('sidebar', ['openSidebar']),
    sidebar() {
      return this.openSidebar;
    },
  },
  methods: {
    ...mapActions('sidebar', ['hiddenSidebar']),
    handleSidebar() {
      const mainApp = document.querySelector('.main-app');
      if (this.sidebar) {
        if (mainApp && !mainApp.classList.contains('section-responsive')) {
          mainApp.classList.add('section-responsive');
        }
      } else if (mainApp && mainApp.classList.contains('section-responsive')) {
        mainApp.classList.remove('section-responsive');
      }
    },
    touchOverlay() {
      this.hiddenSidebar();
    },
    handleSectionsWithoutSidebar() {
      if (this.sidebar && this.hideSideBar) {
        this.hiddenSidebar();
      }
    },
  },
  watch: {
    sidebar() {
      this.handleSidebar();
    },
    $route(to, from) {
      if (this.sidebar && (to.name !== from.name)) {
        this.$nextTick(() => {
          this.handleSidebar();
        });
        if (window.screen.width < 620) this.hiddenSidebar();
      }
    },
  },
  mounted() {
    this.handleSidebar();
    this.handleSectionsWithoutSidebar();
  },
};
</script>

<style scoped>
.overlay-sidebar{
    position:fixed;
    top: 0;
    opacity: 0.2;
    background-color: rgba(0,0,0,0.9);
    height:100%;
    width:100%;
    z-index: var(--z-5);

}
.sidebar {
    position:fixed;
    top: 0;
    width:50%;
    height:100%;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    user-select: none;
    transition: .1s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: var(--z-5);
    border-right: 1px solid rgb(241, 236, 236);
}

@media (min-width: 520px){
  .sidebar {
    width: 15rem;
  }

  .overlay-sidebar{
    display: none;
  }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s, transform 0.1s;
}

.fade-enter-from, .fade-leave-to{
    opacity:0;
    transform: translateX(-30%);
}

</style>
