<template>
    <div :class="customClass">
        <template v-if="link">
            <template v-if="fullRoute.href">
              <a :href="fullRoute.href" :class="['flex flex-row',`${height}`, 'items-center', 'list']">
                <div>
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                </div>
                <div>
                    <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                </div>
              </a>
            </template>
            <template v-else-if="fullRoute.click">
              <div @click="fullRoute.click" :class="['flex flex-row',`${height}`, 'items-center', 'list']">
                <div>
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                </div>
                <div>
                    <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <router-link :active-class="`c-bg-${isActive}`" :to="fullRoute" :class="['flex flex-row',`${height}`, 'items-center', 'list']">
                <div>
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                </div>
                <div>
                    <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                </div>
              </router-link>

            </template>
            
        </template>
        <template v-if="multiLink">
          <div class="flex flex-col">
            <div v-if="topLine" >
                <hr style="height:1px;background-color:#646060;border:none">
            </div>
            <div @click="multiShow = !multiShow" :class="['flex flex-row justify-between items-center cursor-pointer', 'list']">
              <div  :class="['flex flex-row',`${height}`, 'items-center']">
                  <div>
                      <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                  </div>
                  <div>
                      <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                  </div>
              </div>
              <div  class="mr-2 cursor-pointer">
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="multiShow? 'arrow-down' : 'arrow-up'" />
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div  v-if="multiShow" >
                  <div v-for="({text,name, href, click},index) in fullRoutes" :key="index">
                    <template v-if="href" >
                      <a :href="href" :class="['flex flex-row',`${height}`, 'items-center', 'cursor-pointer', 'list']">
                        <div class="ml-6 sm:ml-8">
                            <span :class="[`c-text-${textColor}`,'tracking-tighter']">{{text}}</span>
                        </div>
                      </a>
                    </template>
                    <template v-else-if="click" >
                      <div :class="['flex flex-row',`${height}`, 'items-center', 'cursor-pointer', 'list']">
                        <div class="ml-6 sm:ml-8">
                            <span :class="[`c-text-${textColor}`,'tracking-tighter']">{{text}} ss</span>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <router-link :active-class="`c-bg-${isActive}`" :to="{name}" :class="['flex flex-row',`${height}`, 'items-center', 'cursor-pointer', 'list']">
                        <div class="ml-6 sm:ml-8">
                            <span :class="[`c-text-${textColor}`,'tracking-tighter']">{{text}}</span>
                        </div>
                      </router-link>
                    </template>
                  </div>
              </div>
            </transition>
            <div v-if="bottomLine" >
                <hr style="height:1px;background-color:#646060;border:none">
            </div>
          </div>

        </template>

    </div>
</template>
<script>

export default {
  props: {
    link: {
      type: Boolean,
      default: true,
    },
    fullRoute: {
      type: Object,
    },
    fullRoutes: {
      type: Array,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: 'gray',
    },
    textColor: {
      type: String,
      default: 'white',
    },
    text: {
      type: [String, Array, Object],
      required: true,
    },
    height: {
      type: String,
      default: 'h-10',
    },
    isActive: {
      type: String,
      default: 'gray-active',
    },
    hover: {
      type: String,
      default: 'rgba(255,255,255,.16)',
    },
    multiLink: {
      type: Boolean,
      default: false,
    },
    topLine: {
      type: Boolean,
      default: false,
    },
    bottomLine: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      multiShow: false,
    };
  },

};
</script>

<style scoped>

  .list:hover {
    background:v-bind('hover');
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s, transform 0.2s;
}

.fade-enter-from, .fade-leave-to{
    opacity:0.1;

}
</style>
