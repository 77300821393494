export const COVERAGE_TYPE = [
  { id: 1, name: 'Correo' },
  { id: 2, name: 'Terminal' },
  { id: 3, name: 'Recolector' },
  { id: 4, name: 'Comercio' },
];

export const COVERAGE_TYPES = {
  CORREO: 1,
  TERMINAL: 2,
  COLLECTOR: 3,
  COMMERCE: 4,
};

export const AGREEMENT_TYPES = {
  VISIT: 1,
  COMMERCE: 2,
  CORREO: 3,
  TERMINAL: 4,
};

export const OPTION_TYPES = {
  ASSIGNED: 1,
  PM: 2,
  ALL: 3,
};

export const CONTACT_CHANNELS = {
  WHATSAPP: 1,
  TEXT_MESSAGE: 2,
  EMAIL: 3,
  CALL: 4,
};

export const VISIT_TYPES = {
  VISIT: 1,
  TOMORROW: 2,
  ROUTE: 3,
};

export const getMyCoordinates = () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
  };
  return new Promise(((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  }));
};
