<template>
    <div ref="mainMenu" class="main-menu">
        <div  @click.stop="touchOverlay" v-if="menu && overlay" class="overlay-menu"></div>
        <slot name="activator" ></slot>
        <div :style="[`${leftMenuContainer ?? ''}`]"  ref="menuContainer" class="menu-container" >
            <template v-if="cache">
              <div v-show="menu">
                <slot name="menuContent" ></slot>
              </div>
            </template>
            <template v-else>
              <div v-if="menu">
                <slot name="menuContent" ></slot>
              </div>
            </template>

        </div>
    </div>
</template>

<script>

export default {
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
    cache: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      leftMenuContainer: null,
      visible: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.setPositionMenu);
    this.setPositionMenu();
  },
  methods: {
    setPositionMenu() {
      // console.log(this.$refs.mainMenu.clientHeight);
      const mainMenu = this.$refs.mainMenu.getBoundingClientRect();
      const menuContainer = this.$refs.menuContainer.getBoundingClientRect();
      if (Math.ceil(menuContainer.left + this.$refs.menuContainer.offsetWidth) >= window.innerWidth) {
        this.leftMenuContainer = `left:${mainMenu.left - (menuContainer.width - mainMenu.width)}px;`;
      }
    },
    touchOverlay(evt) {
      if (!this.$refs.menuContainer.contains(evt.target)) {
        if (this.menu) {
          this.$emit('exit');
        }
      }
    },
  },
  watch: {
    menu(val) {
      this.visible = val;
      this.$nextTick(() => {
        this.setPositionMenu();
      });
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.setPositionMenu);
  },

};
</script>

<style scoped>

.overlay-menu{
    position:fixed;
    top: 0;
    left:0;
    opacity: 0.1;
    background-color: rgba(0,0,0,0.7);
    height:100%;
    width:100%;
    z-index: 2;
}

.menu-container{
    position: fixed;
    z-index: 3;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.fade-enter-from, .fade-leave-to{
    opacity:0;
    transform: translateX(-10%);
}

</style>
