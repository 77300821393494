<template>
    <div :class="[' grid grid-cols-12 items-center', `c-bg-${background}`,'navbarExpress', `${border}`]">
        <template v-if="back && firstBlock">
            <router-link :to="fullRoute" :class="['ml-2', `${firstBlockClass}`]">
                <font-awesome-icon :class="[`c-text-${iconColor}`]"  icon="arrow-left" />
            </router-link>
        </template>
        <template v-else-if="firstBlock">
            <template v-if="!openSidebar">
                <div  :class="['ml-2 cursor-pointer', `${firstBlockClass}`,'flex flex-row items-center']">
                    <font-awesome-icon @click="setBar()" :class="[`c-text-${iconColor}`]" icon="bars" />
                    <template v-if="titleSection">
                        <div :class="titleSectionClass">
                            {{titleSection}}
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <template v-if="titleSection">
                    <div :class="titleSectionClass">
                        {{titleSection}}
                    </div>
                </template>
            </template>
        </template>
        <div v-if="secondBlock.visible" :class="[`${secondBlockClass }`]">
            <slot name="secondContent"></slot>
        </div>
        <div v-if="thirdBlock.visible" :class="[`${thirdBlock.class ?? thirdBlockClass} flex justify-end mr-2 `]">
          <slot name="thirdContent">
            <div  class="relative">
              <font-awesome-icon @click="$emit('clickNavbarThirdBlock')" :class="['cursor-pointer',`c-text-${thirdBlock.iconColor ?? iconColor}`]" :icon="thirdBlock.icon ?? 'store'" />
              <div
              v-if="thirdBlock.tag?.visible && (!thirdBlock.tag.hiddenCount || (thirdBlock.tag.value) )"
              :class="['tagThirdBlock', `${thirdBlock.tag?.background ?? 'c-bg-gray-light' }`, `${thirdBlock.tag?.textColor ?? 'c-text-black'}`]">
                {{thirdBlock.tag.value ?? ''}}
              </div>
            </div>
          </slot>

        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    firstBlock: {
      type: Boolean,
      default: true,
    },
    secondBlock: {
      type: Object,
      default() {
        return { visible: true };
      },
    },
    scondSlot: {
      type: String,
      default: '',
    },
    thirdBlock: {
      type: Object,
      default() {
        return { visible: true };
      },
    },
    back: {
      type: Boolean,
      default: false,
    },
    fullRoute: {
      type: [Object, String],
    },
    background: {
      type: String,
      default() {
        return 'white';
      },
    },
    titleColor: {
      type: String,
      default() {
        return 'black';
      },
    },
    iconColor: {
      type: String,
      default() {
        return 'gray';
      },
    },
    iconLeft: {
      type: Boolean,
      default: true,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    titleSection: {
      type: String,
      default: '',
    },
    titleSectionClass: {
      type: String,
      default: 'ml-2',
    },
    border: {
      type: String,
      default: 'border border-t-gray-200',
    },
  },
  computed: {
    ...mapState('sidebar', ['openSidebar']),
    firstBlockClass() {
      if (this.secondBlock.visible && this.thirdBlock.visible) {
        return 'col-span-2';
      }
      return 'col-span-6';
    },
    secondBlockClass() {
      if ((this.firstBlock && this.thirdBlock.visible) || (!this.firstBlock && this.thirdBlock.visible) || (this.firstBlock && !this.thirdBlock.visible)) {
        return 'col-span-8';
      }
      return 'col-span-12';
    },
    thirdBlockClass() {
      if (this.firstBlock && this.secondBlock.visible && !this.openSidebar) {
        return 'col-span-2';
      } if (this.firstBlock && this.secondBlock.visible && this.openSidebar) {
        return 'col-span-4';
      }
      return 'col-span-6';
    },
  },
  methods: {
    ...mapActions('sidebar', ['setSidebar']),
    setBar() {
      this.setSidebar();
    },
  },
};
</script>

<style>
.navbarExpress{
    height:55px;
}

.tagThirdBlock{
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  top:-10px;
  left: -12px;
  height:20px;
  width:20px;
  border-radius: 50%;
  font-size: 13px;
}

</style>
