const Customers = () => import(/* webpackChunkName: "Equipment" */ '@/views/Equipments/Customers.vue');
const EquipmentManagement = () => import(/* webpackChunkName: "Equipment" */ '@/views/Equipments/EquipmentManagement.vue');
const Equipment = () => import(/* webpackChunkName: "Equipment" */ '@/views/Equipments/Equipment.vue');
const Transaction = () => import(/* webpackChunkName: "Equipment" */ '@/views/Equipments/Transaction.vue');
const HistoryManagedEquipments = () => import(/* webpackChunkName: "Equipment" */ '@/views/Equipments/HistoryManagedEquipments.vue');
const HistoryEquipments = () => import(/* webpackChunkName: "Equipment" */ '@/views/Equipments/HistoryEquipments.vue');

const EquipmentManagementRoute = [
  // gestion de equipos
  {
    path: '/customers/:q*',
    name: 'customers',
    component: Customers,
    meta: { gate: 'equipment_recover', requiresAuth: true },
  },
  {
    path: '/equipment/manage/:hash/:identificacion/:order',
    name: 'equipmentManagement',
    component: EquipmentManagement,
    meta: { gate: 'equipment_recover', requiresAuth: true },
    children: [
      {
        path: 'equipment/:equipment_id/:autorize?',
        name: 'equipmentInTransactionInManagement',
        component: Equipment,
      },
      {
        path: 'transaction',
        name: 'equipmentTransaction',
        component: Transaction,
      },
    ],
  },
  {
    path: '/agreement/manage/:agreement_id/:identificacion/:order/:hash',
    name: 'agreementManagement',
    component: EquipmentManagement,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'equipment/:equipment_id/:autorize?',
        name: 'equipmentOfAgreementInManagement',
        component: Equipment,
      },
      {
        path: 'transaction',
        name: 'agreementTransaction',
        component: Transaction,
      },
    ],
  },
  // historial de equipos gestionados
  {
    path: '/history-equipment-managements/:q*',
    name: 'historyEquipmentManagements',
    component: HistoryManagedEquipments,
    meta: { gate: 'equipment_recover', requiresAuth: true },
  },
  {
    path: '/history-equipments/:q*',
    name: 'historyEquipments',
    component: HistoryEquipments,
    meta: { gate: 'equipment_recover', requiresAuth: true },
  },
];
export default EquipmentManagementRoute;
